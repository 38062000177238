export default {
  assettradTitle: "Climate Assets Trading",
  assettradSubTitle1: "Achieving emission reduction targets is an important and long-term process. The products ",
  assettradSubTitle2: "provided by QYH can effectively accelerate your process of achieving this goal.",
  
  assettradMapItem1: "Expertise in the international and Chinese carbon markets",
  assettradMapItem2: "Millions of high-quality credits sold on the international market",
  assettradMapItem3: "Core team of industry experts with post-graduate, master’s, and PhD degrees",
  assettradMapItem4: "Technical team specialized in nature-based carbon sink measurement and project development",

  assettradProjectItem1Title: "Carbon Project",
  assettradProjectItem1SubTitle: "QYH focuses on Nature-Based projects and can provide various carbon offset resources, including Afforestation, Sustainable Grassland Management, and IFM.",
  assettradProjectItem2Title: "EAC",
  assettradProjectItem2SubTitle: "QYH can provide three types of EAC: solar, wind and hydro, helping businesses achieve their renewable energy consumption goals.",

}

 



