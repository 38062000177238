export default {
  grasslandTitle: "草原可持续管理碳汇项目",
  sustainableTitle: "草原可持续管理碳汇项目开发为何如此重要？",
  sustainableContent1: "草原是生态系统的重要组成部分，起到调控水源、控制土壤侵蚀的作用，并为授粉昆虫提供栖息地。有效的草原管理可以增加土壤中的有机碳（SOC），从而缓解气候变化带来的影响。草原储存碳的能力主要来自植物发达的根系，具有极强的固土保水能力。从全球范围来看，在一米深的土壤内，草原就可以储存约3430亿吨碳，潜在土壤固碳速率为每年每公顷0.5吨。鉴于草原的规模和广泛分布，它们在陆地碳循环中的作用至关重要。",
  sustainableContent2: "然而，人类活动，如农作物种植和畜牧业对草原造成的影响日益增加，许多天然草地已经受到破坏，其土壤有机碳含量减少。[1]",
  sustainableContent3: "千予汇开发的草原可持续管理项目旨在恢复过度放牧和自然气候原因导致的草地退化，并保持生态系统的可持续性。通过有效的措施，增强草原土壤的固碳能力。[2]根据经核证的国际方法学，项目采用以下可持续管理措施：",
  sustainableContentli1: "在不同区域间实行轮牧制度；",
  sustainableContentli2: "在已退化的草地区域减少放牧；",
  sustainableContentli3: "在严重退化的区域补种本地草种；",
  sustainableContentli4: "与当地居民以及政府合作，确保草地管理得到实施。",
  sustainablePicOrigin: "图片为甘肃瓜州草原可持续管理项目（VCS 4218）的初期阶段，图中为退化草地围栏以及火警瞭望塔。",


  grasslandCarbonTitle: "草原可持续管理项目属于“碳清除“还是“碳减排”？",
  grasslandCarbonContent1: "一般而言，碳清除是指将清除大气中的碳然后储存在其他地方，比如存储在生态系统中（如草地或森林）或地下岩层中（如碳捕获和碳储存技术）。相反，碳减排是指减少进入大气的碳，比如从化石能源转向太阳能或风能发电，或者使用清洁炉灶（取代木材燃料）。",
  grasslandCarbonContent2: "鉴于草原可持续管理项目既涉及碳清除，又包含了碳减排。通过第三方验证的数据显示，千予汇草原可持续管理项目产生的总碳汇量中，98%来自碳清除措施，2%来自碳减排措施。这表示项目的绝大多数碳汇量来自于清除大气中的现有碳，而不仅仅是减少碳排放量。",
  grasslandCarbonContent3: "如上所述，草原固碳的能力源自其强大的根系。因此，与其碳减排贡献相比，草原可持续管理项目在碳清除方面具有更高的贡献。除增加土壤有机碳（碳清除）外，该项目还可以达到以下的碳减排目标：",
  carbonContentli1: "减少化肥的使用",
  carbonContentli2: "减少生物质的燃烧",
  carbonContentli3: "减少粪便的沉积和堆积",
  carbonContentli4: "减少动物呼吸/肠道发酵产生的排放",
  carbonContentli5: "减少农业设备化石燃料使用",
  carbonContent4: "简而言之，草原可持续管理项目不仅仅是一个碳清除（carbon removal）或碳减排（carbon reduction）项目，而是一种综合的方法，既促进生态系统恢复，同时还支持可持续农业活动。",

  carbonLegend1Title: "碳清除",
  carbonLegend1Content1: "增加土壤有机碳（SOC）储存量",
  carbonLegend1Content2: "通过放牧管理和重新植被进行草原恢复",
  carbonLegend2Title: "碳减排",
  carbonLegend2Content1: "减少非CO2类温室气体的排放",
  carbonLegend2Content2: "减少放牧时间",
  carbonLegend2Content3: "*牲畜的总量不减少",

  verificationTitle: "草原可持续管理项目如何核证？",
  verificationContent: "为了确保草原可持续管理项目具有额外性，并且其碳汇具有高度的完整性和质量，需要采取多个步骤来与当地利益相关者设计和实施项目行动，并由第三方核实其行动，并最终发行碳汇。最终用户可以使用这些碳汇来实现其净零气候目标。该过程简述如下：",
  verificationItem1: "项目选址：进行全面的项目可行性研究，选择符合方法学要求的区域（历史上受到各种程度退化影响并需要额外财政支持的草地生态系统）。",
  verificationItem2: "项目开发：在项目实施之前，为该区域量身定制一个恢复计划，并咨询当地利益相关者的意见。",
  verificationItem3: "恢复与管理：实施草地恢复和可持续管理措施，例如草种播种、篱笆建造、休息和轮换放牧、当地参与和就业。",
  verificationItem4: "验证与核证：千予汇与第三方合作，按照国际标准完成项目验证和核证程序。（千予汇使用的标准为VCS，这是最广泛使用的国际碳标准。）",
  verificationItem5: "碳汇签发与销售：项目经过核证后，就可以签发碳汇。买家可以使用这些袒护来实现其气候目标。为了确保项目的长期可持续管理（包括其所有的环境、社会和经济效益），财政支持至关重要。",
  verificationItem6: "长期监测：完整性是高质量碳项目的关键组成部分。在项目的整个生命周期中，千予汇致力于保持数据准确性和透明度。与当地利益相关者合作，持续进行项目监测，以保证未来的碳汇签发和项目的持续积极影响。",

  contributionTitle: "对当地社区的贡献",

  carouselTitle1: "改善生计",
  carouselContent1: "在项目期间，当地居民将获得可持续的收入来源，并增加就业机会。",
  carouselTitle2: "气候影响",
  carouselContent2: "增强草地的固碳能力，使当地社区和自然生态系统更能抵御气候变化的影响。",
  carouselTitle3: "增强生物多样性",
  carouselContent3: "扩大和保护当地植物和动物的栖息地，促进生物多样性的繁荣，并创造积极的反馈循环。",
  
  acceptanceTitle: "千予汇致力于提供环境服务，并始终坚守您的气候承诺。",
  acceptanceSubTitle: "草原可持续管理项目",
  acceptanceBUtton: "了解更多",
  
}







