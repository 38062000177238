export default {
  home: "Home",
  news: "News",
  whatWeDo: "What We Do",
  projects: "Projects",
  aboutUs: "About Us",
  contactUs: "Contact Us",

  whatWeDoSub1: "Project Development",
  whatWeDoSub2: "Climate Asset Trading",
  whatWeDoSub3: "Corporate Climate Commitments",
  whatWeDoSub4: "Energy Attribute Certificates (EAC) ",
  whatWeDoSub5: "Sustainable Grassland Management Project",


}

 



