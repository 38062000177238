import Vue from "vue";
import VueI18n from "vue-i18n";
import nav from "./nav/index";   // 导航
import footer from "./footer/index";    //  底部
import index from "./index/index";    //  首页
import serviceLocal from "./service/index";    //  服务
import about from "./about/index";    //  关于
import info from "./info/index";    //  关于
import corporate from "./corporate/index";    //  关于
import assetTrad from "./assetTrad/index";    //  关于
import certificate from "./certificate/index";    //  关于
import carbon from "./carbon/index";    //  关于
import grassland from "./grassland/index";    //  关于
import guazhou from "./guazhou/index";    //  关于
import enLocale from 'element-ui/lib/locale/lang/en' ;       //element-ui英文语言包
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' ;    //element-ui中文语言包


Vue.use(VueI18n)

// 获取当前浏览器的语言
let  language = navigator.language;
if(language != "zh-CN" && language != "en-US"){
    language = "en-US";
}

const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || language,  //  zh-CN    en-US
    messages: {
        "zh-CN": {
            ...zhLocale,
            ...nav.zh,
            ...footer.zh,
            ...index.zh,
            ...serviceLocal.zh,
            ...about.zh,
            ...info.zh,
            ...corporate.zh,
            ...assetTrad.zh,
            ...certificate.zh,
            ...carbon.zh,
            ...grassland.zh,
            ...guazhou.zh,
        },
        "en-US": {
            ...enLocale,
            ...nav.en,
            ...footer.en,
            ...index.en,
            ...serviceLocal.en,
            ...about.en,
            ...info.en,
            ...corporate.en,
            ...assetTrad.en,
            ...certificate.en,
            ...carbon.en,
            ...grassland.en,
            ...guazhou.en,
        }
    }
})

export default i18n
