export default {
  infoName: "Name*",
  infoEmail: "Email*",
  infoCompanyName: "Company Name",
  infoMeg: "Message*",
  infoSubmit: "SUBMIT",
  moreUs1: "Contact Us",
  moreUs2: "To Learn More.",
}

 



