<template>
  <div class="header center" :class="locale !== 'zh-CN' ? 'header-en' : ''">
    <img class="header-logp" src="@/assets/images/header/logo-header.png" alt="logo" v-if="logoFlag">
    <img src="@/assets/images/header/detail-logo.png" alt="logo" v-else>
    <ul :class="logoFlag?'nav':'nav nav-color'">
      <li>
        <router-link :to="{path:'/index'}">{{$t("home")}}</router-link>
      </li>
      <li>
        <a :href="`${$t('ahref')}`">{{$t("news")}}</a>
      </li>
      <li class="header-position">
        <router-link :to="{path:'/provideService'}">{{$t("whatWeDo")}}</router-link>
        <div class="header-two-select">
          <router-link :to="{path:'/carbon'}">{{$t("whatWeDoSub1")}}</router-link>
          <router-link :to="{path:'/asset-trad'}">{{$t("whatWeDoSub2")}}</router-link>
          <router-link :to="{path:'/corporate-climate-commitment'}">{{$t("whatWeDoSub3")}}</router-link>
          <router-link :to="{path:'/green-certificate'}">{{$t("whatWeDoSub4")}}</router-link>
        </div>
      </li>
      <li class="header-position">
        <router-link :to="{path:'/project'}">{{$t("projects")}}</router-link>
        <div class="header-two-select">
          <router-link :to="{path:'/project'}">{{$t("projectClassItemTitle1")}}</router-link>
          <router-link :to="{path:'/project'}">{{$t("projectClassItemTitle2")}}</router-link>
          <router-link :to="{path:'/project'}">{{$t("projectClassItemTitle3")}}</router-link>
        </div>
      </li>
      <li><router-link :to="{path:'/aboutUs'}">{{$t("aboutUs")}}</router-link></li>
      <li @click="scollButton">{{$t("contactUs")}}</li>
      <li>
        <span @click="changeLangageEn">En</span>/
        <span @click="changeLangageZh">中文</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "public-header",
  props:{
    // logoFlag: Boolean,
    // default: "true"
    logoFlag: {
      type:Boolean,
      default: true
    }
  },
  data(){
    return {
      pageHeight:0,
      locale: "",
      // logo:require("@/assets/images/header/logo-header.png")
    }
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },
  mounted(){
    this.getDomHeight();
  },
  methods: {
    scollButton() {
      let timer = null;
      let count = 0;
      timer = setInterval(() => {
        if(count >= 10){
          clearInterval(timer)
        }
        count++;
        window.scrollTo(0,(this.pageHeight/10)*count);
      },20)
    },
    getDomHeight() {
      this.pageHeight = document.getElementById("app").clientHeight;
    },
    // 切换语言
    changeLangageEn(){
      localStorage.setItem('locale',"en-US");
      location.reload();
    },
    changeLangageZh(){
      localStorage.setItem('locale',"zh-CN");
      location.reload();
    }
  },
  
}
</script>
<style scoped>
.header{
  height: 60px;
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  /* position: fixed; */
}
.header-logp {
  height: 40px;
  width: 201px;
}
.nav {
  display: flex;
  justify-content: flex-start;
}
.nav li{
  padding: 0 17px;
  font-size: 12px;
  font-weight: 600;
  line-height: 40px;
  font-family: PingFangSC-Regular, PingFang SC;
  cursor: pointer;
  letter-spacing: 1px;
}
.nav li a,.nav li{
  color: #FFFFFF;
}
.nav-color li a,.nav-color li {
  color: #333;
}
.nav .header-position {
  position: relative;
}
.nav .header-position:hover .header-two-select{
  display: block;
}
.header-two-select {
  display: none;
  position: absolute;
  top: 40px;
  padding: 15px;
  background: rgba(211, 217, 203, 0.60);
  
  border-radius: 20px;
}
.header-two-select a {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 17px;
  letter-spacing: 1px;
  font-style: normal;
  margin-bottom: 20px;
  min-width: 180px;
}
.header-two-select a:last-child {
  margin-bottom: 0;
}
.header-en .header-two-select a{
  min-width: 260px !important;
}
</style>