<template>
  <div class="footer">
   <div class="footer-inner-wrap">
    <ul class="ul">
      <li class="address">
        <p>{{$t("address1")}}{{$t("address2")}}{{$t("address3")}}</p>
      </li>
      <li class="contact">
        <p>{{$t("phone")}}</p>
        <p>{{$t("website")}}</p>
      </li>
    </ul>
    <ul>
      <li class="link">
        <a href="https://twitter.com/QYH_Investment">
          <img src="../../assets/images/footer-mobile/twitter.png" alt="twitter">
        </a>
        <a href="https://www.linkedin.com/company/14606643/admin/">
          <img src="../../assets/images/footer-mobile/linkedin.png" alt="linkedin">
        </a>
      </li>
    </ul>
   </div>
    <p class="copyright">2021  <svg t="1619772064820" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="859" width="12" height="12"><path d="M512 96c229.76 0 416 186.24 416 416s-186.24 416-416 416S96 741.76 96 512 282.24 96 512 96z m0 64C317.6 160 160 317.6 160 512s157.6 352 352 352 352-157.6 352-352S706.4 160 512 160z m0 128c56.032 0 108.96 20.704 149.76 57.44a225.024 225.024 0 0 1 40.224 47.872l-54.336 33.92-2.016-3.264a160.96 160.96 0 0 0-20.704-25.312l-5.952-5.632a160 160 0 1 0 26.688 206.976l2.048-3.2 54.176 33.888-2.816 4.576c-8.352 12.64-17.952 24.416-28.672 35.104l-8.224 7.84A224 224 0 1 1 512 288z" fill="#ffffff" p-id="860"></path></svg> {{$t("companyName")}}</p>
  </div>
</template>
<script>
export default {
  name: "publicFooter",
  data(){
    return {
      
    }
  },

}
</script>
<style scoped>
.footer {
  height: 2.52rem;
  background-color: #232323;
  box-sizing: border-box;
  padding: 0.4rem 0.3rem;
}
.footer-inner-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.35rem;
}
.footer-inner-wrap p {
  font-size: 0.16rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #EAEAEA;
  line-height: 0.4rem;
  text-align: left;
  margin-top: 0.04rem;
}
.link {
  padding-right: 0.66rem;
  margin-top: 0.5rem;
}
.link a {
  margin-left: 0.3rem;
  width: 0.36rem;
  height: 0.36rem;
}
.link a:first-child {
  margin: 0;
}
.link a img {
  width: 0.36rem;
  height: 0.36rem;
  object-fit: contain;
}
.footer svg {
  position: relative;
  top: 0.03rem;
}
.footer p.copyright {
  font-size: 0.14rem;
  color: #A8A8A8;
  text-align: center;
}
.word-spacing .footer-inner-wrap .ul{
  width: 5rem;
}
.word-spacing .footer-inner-wrap{
  margin-bottom: 0;
}
</style>