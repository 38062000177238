export default {
  home: "首页",
  news: "最新消息",
  whatWeDo: "我们提供的服务",
  projects: "碳汇项目",
  aboutUs: "关于我们",
  contactUs: "联系我们",

  whatWeDoSub1: "碳减排项目开发",
  whatWeDoSub2: "环境资产交易",
  whatWeDoSub3: "企业气候承诺",
  whatWeDoSub4: "绿证",
  whatWeDoSub5: "草原可持续管理碳汇项目",
}