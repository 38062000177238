<template>
  <div class="footer">
    <ul class="footer-inner center">
      <li class="logo">
        <img src="../../assets/images/footer/logo-footer.png" alt="logo">
      </li>
      <li class="address">
        <p>{{$t("address1")}}<br />{{$t("address2")}}<br />{{$t("address3")}}</p>
      </li>
      <li class="contact">
        <p>{{$t("pleaseContactUs")}}</p>
        <p>{{$t("phone")}}</p>
        <p>{{$t("website")}}</p>
      </li>
      <li class="link">
        <a href="https://twitter.com/QYH_Investment">
          <img src="../../assets/images/footer/twitter.png" alt="twitter">
        </a>
        <a href="https://www.linkedin.com/company/14606643/admin/">
          <img src="../../assets/images/footer/linkedin.png" alt="linkedin">
        </a>
      </li>
    </ul>
    <p class="font-opacity">2021  <svg t="1619772064820" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="859" width="18" height="18"><path d="M512 96c229.76 0 416 186.24 416 416s-186.24 416-416 416S96 741.76 96 512 282.24 96 512 96z m0 64C317.6 160 160 317.6 160 512s157.6 352 352 352 352-157.6 352-352S706.4 160 512 160z m0 128c56.032 0 108.96 20.704 149.76 57.44a225.024 225.024 0 0 1 40.224 47.872l-54.336 33.92-2.016-3.264a160.96 160.96 0 0 0-20.704-25.312l-5.952-5.632a160 160 0 1 0 26.688 206.976l2.048-3.2 54.176 33.888-2.816 4.576c-8.352 12.64-17.952 24.416-28.672 35.104l-8.224 7.84A224 224 0 1 1 512 288z" fill="#ffffff" p-id="860"></path></svg> {{$t("companyName")}}</p>
  </div>
</template>
<script>
export default {
  name: "publicFooter",
  data(){
    return {
      
    }
  },

}
</script>
<style scoped>
.footer{
  width: 100%;
  min-width: 1200px;
  height: 300px;
  background: #232323;
}
.footer-inner{
  box-sizing: border-box;
  padding-top: 95px;
  height: 260px;
  display: flex;
  justify-content: flex-start;
  
}
.footer-inner .logo{
  width: 133px;
  height: 40px;
  margin-right: 216px;
}
.footer-inner .address ,.footer-inner .contact{
  width: 275px;
  height: 48px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 24px;
  margin-right: 25px;
}
.footer-inner .address p ,.footer-inner .contact p{
  color: #FFFFFF;
  text-align: left;
}
.link {
  margin-left: 155px;
}
.link a:first-child{
  margin-right: 20px;
}
.footer p {
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 24px;
  margin-right: 25px;
  color: #FFFFFF;
}
.footer p svg {
  position: relative;
  top: 3px;
}
.word-spacing .footer-inner .address p ,.word-spacing  .footer-inner .contact p{
  margin-right: 0;
}
.font-opacity {
  opacity: 0.44
}

</style>