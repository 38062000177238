export default {
  bannerTitle:"重视每一个环保承诺",
  moreTitle:"了解更多",
  //  服务开始
  serviceTitle:"我们提供的服务",
  serviceContentTitle:"碳汇项目开发、碳汇销售、促进可持续发展&帮助消除贫困、碳中和推广、碳中和咨询",
  serviceItem1:"VCS&CCB林业碳汇",
  serviceItem2:"保护中国多样化的生态系统 ",
  serviceItem3:"植树造林&森林经营管理…",
  //  服务结束


  // h5
  projectItemTitle1Mobile:"江西乐安森林碳汇项目",
  projectItemTitle2Mobile:"内蒙古原始森林保护项目——乌尔旗汉",
  projectItemTitle3Mobile:"内蒙古原始森林保护项目—克一河林场",
  projectItemTitle4Mobile:"河南省方城、唐河植树造林项目",



  //  项目开始
  projectTitle:"碳汇项目",
  projectTitle1:"碳汇项目",
  projectTitleTitle:"千予汇环保项目的开发以及项目的详情",
  projectItemTitle1:"江西乐安森林碳汇项目",
  projectItemyear1:"2006-2035",
  projectItemTitle2:"内蒙古原始森林保护项目——乌尔旗汉",
  projectItemyear2:"2013-2042",
  projectItemTitle3:"内蒙古原始森林保护项目—克一河林场",
  projectItemyear3:"2013-2042",
  projectItemTitle4:"南召县植树造林项目",
  projectItemyear4:"2014-2073",
  projectItemTitle5:"洪山镇森林生态保护项目",
  projectItemyear5:"2015-2044",
  projectItemTitle6:"河南省方城、唐河植树造林项目",
  projectItemyear6:"2015-2075",
  projectItemTitle7:"内蒙古原始森林保护项目——乌尔旗汉",
  projectItemyear7:"2013-2042",
  projectItemTitle10:"内蒙古原始森林保护项目",
  projectItemTitle101:"乌尔旗汉",
  projectItemTitle8:"内蒙古原始森林保护项目—克一河林场",
  projectItemTitle11:"内蒙古原始森林保护项目",
  projectItemTitle111:"克一河林场",
  projectItemyear8:"2013-2042",
  projectItemTitle9:"洪山镇森林生态保护项目",
  projectItemyear9:"2015-2044",
  projectItemTitle12:"河南省方城、唐河",
  projectItemTitle121:"植树造林项目",
  
  //  项目结束
  // 消息开始
  newsTitle:"最新消息",
  newsContentTitle:"关注千予汇，了解千予汇的最新消息。",
  newsDetailed:"历史上方城、唐河的森林曾是温带落叶混交林和亚热带混交林，本项目选择了多种本地树种，即杨树、木兰、刺槐、柏树、松树、马尾松、核桃数。两县共有57科180余种木本植物和50余科300余种草本植物……",
 // 消结束
//  会员单位
  memberOrganizations:"会员单位",
  // lianjie
  clientsPartners:"合作伙伴",
  // 项目详情
  projectJiangXiDetail1:"该项目位于江西省乐安县，属亚热带湿润季风气候区。在项目实施之前，这片土地的树木可以用于木材销售。自2006年作为碳汇项目认证之后，项目所在地纳入改善森林管理(IFM)项目的一部分，根据树种和年龄将土地划分为5个生态恢复区，森林的总体碳储存能力随着时间的推移而增加。",
  projectJiangXiDetail2:"该项目有助于保护土壤和当地的生物多样性，促进森林的可持续发展，30年项目期内将减少2,600,406吨温室气体排放。",
  projectJiangXiTitle1: "减少贫困",
  projectJiangXiTitleDetail1: "乐安县是全国扶贫开发工作重点县。该项目的开发可以提高当地的经济效益，增加当地居民的平均收入。",
  projectJiangXiTitle2: "森林保护",
  projectJiangXiTitleDetail2: "项目实施之前，该地区存在树木轮伐现象。项目开始之后，所有的伐木活动都会停止。当地的森林碳储备量逐年提升。",
  projectJiangXiTitle3: "改善居民的生活健康",
  projectJiangXiTitleDetail3: "因地制宜种植树木，尽量减少或者不使用杀虫剂，用混合种植的方式预防病虫害的发生。",
  projectJiangXiTitle4: "环境保护",
  projectJiangXiTitleDetail4: "当地禁止“刀耕火种”这种古老的农业耕作方式，减少空气污染，保护环境，避免发生因树木砍伐而造成的水土流失。",
  projectJiangXiRightDetail1:"VCS标准",
  projectJiangXiRightDetail2:"每年减少866,802吨碳排放",
  projectJiangXiRightDetail3:"7,746.7公顷的森林得到保护",
  projectJiangXiRightDetail4:"覆盖乐安县8个镇",
  projectJiangXiRightDetail5:"项目期共30年（2006-2035）",
  projectJiangXiRightDetail6:"项目实现的可持续发展目标：",
  // 内蒙乌尔旗汉
  projectInnerWuerqihanbanner1:"内蒙古原始森林保护项目——乌尔旗汉",
  projectInnerWuerqihanbanner2:"",
  projectInnerWuerqihanTitle1:"消除贫困",
  projectInnerWuerqihanTitleDetail1:"通过定期的森林经营为当地农民创造收入，提高人民的生活水平。在创造就业方面，目前，乌尔旗汉林业局已经建立了一个碳汇管理办公室，雇用了5名员工。并且在9个林场中，分别设立了碳汇监测站，雇用了45名员工。根据最新统计数据，乌尔旗汉林业碳汇项目收益每增加1元，就可以带来2-10元的社会效益。",
  projectInnerWuerqihanTitle12:"保护原始森林",
  projectInnerWuerqihanTitleDetail2:"该项目的实施，有效控制了区域内的水土流失和土地退化的问题，并通过保护现有森林，提高森林管理，增加森林面积，减少了温室气体的排放。随着该项目的实施，从砍伐森林变成了受保护森林，每年减少约376,087吨温室气体的排放。",
  projectInnerWuerqihanTitle13:"经济效益",
  projectInnerWuerqihanTitleDetail3:"乌尔旗汉拥有独特的自然环境和地理位置，每年都会吸引大量游客来此地游玩。特别是近年来，由于该地区实施了多项生态保护措施，森林砍伐从木材行业逐渐停止，取而代之的是发展旅游业和绿色生态产品。目前，酒店、餐饮、手工艺品和林产品已成为当地的重点产业。",
  projectInnerWuerqihanRightDetail1: "VCS标准",
  projectInnerWuerqihanRightDetail2: "年平均减排量376,087吨",
  projectInnerWuerqihanRightDetail3: "43,167公顷的森林得到保护",
  projectInnerWuerqihanRightDetail4: "创造45个就业岗位",
  projectInnerWuerqihanRightDetail5: "项目期30年（2013-2042）",
  projectInnerWuerqihanRightDetail6: "项目实现的可持续发展目标：",
  projectInnerWuerqihanMap1:"内蒙古原始森林保护项目 ",
  projectInnerWuerqihanMap2:"（乌尔旗汉）",
  // 内蒙克一河林场
  projectInnerKeyihebanner1:"内蒙古原始森林保护项目——克一河林场",
  projectInnerKeyihebanner2:"",
  projectInnerKeyiheTitle1:"森林保护和野生动植物保护",
  projectInnerKeyiheTitleDetail1:"每年，克一河林业局都会实施专项行动，整顿滥伐滥伐的现象。林业局宣传森林保护的重要性，积极执行野生动物保护法。此外，克一河林业局推广“湿地保育日及鸟类保护周”活动，以及组织和推行森林防火防灾活动。林业局的这些努力，增强了野生动物和环境保护的重要性，并提高了当地社区的环保意识。",
  projectInnerKeyiheTitle12:"保护生物多样性",
  projectInnerKeyiheTitleDetail2:"项目区曾经是茂密的原始森林，野生动植物的数量和生物多样性都很丰富。在碳汇项目开发之前，由于当地伐木行业的存在，野生动植物的数量和生物多样性已经减少。该项目的实施提高了生态系统的稳定性，并通过增加森林覆盖和自然栖息地的连通性，加强了生物多样性的保护。",
  projectInnerKeyiheTitle13:"经济效益",
  projectInnerKeyiheTitleDetail3:"由于对项目内森林的保护和管理，当地的空气和水质得到了改善。一些当地人借此机会发展旅游业。这一新的经济互动带动了项目区及周边地区居民的年平均收入。",
  projectInnerKeyiheRightDetail1: "VCS标准",
  projectInnerKeyiheRightDetail2: "平均每年减少100,279吨碳排放",
  projectInnerKeyiheRightDetail3: "20,526公顷森林得到保护",
  projectInnerKeyiheRightDetail4: "创造200个就业岗位",
  projectInnerKeyiheRightDetail5: "项目期30年（2013-2042）",
  projectInnerKeyiheRightDetail6: "项目实现的可持续发展目标：",
  projectInnerKeyiheMap1:"内蒙古原始森林保护项目",
  projectInnerKeyiheMap2:"（克一河林场）",
  // 南召 
  projectNanZhaobanner1:"南召县植树造林项目",
  projectNanZhaoTitle1:"对气候的影响",
  projectNanZhaoTitleDetail1:"项目活动的实施增加了当地森林覆盖率，预计60年项目期内会减少约3100万吨温室气体排放。我们还设立了一个监测系统，核查森林碳汇减少温室气体的情况。第一个监测期间已核查了418,299 吨碳减排量。随着森林面积的增长，该数字会逐渐增加，整个区域内碳储存能力也不断提高。",
  projectNanZhaoTitle12:"对动植物的影响",
  projectNanZhaoTitleDetail2:`本项目位于伏牛山国家级自然保护区南麓，
  拥有金钱豹、大鲵等50余种国家重点保护野生动物。
  虽然目前在项目范围内没有这些物种，
  但植树活动完成后，生态环境将得到很大改善，这将扩大野生保护动物的栖息地。`,
  projectNanZhaoTitleDetail21:"在种植活动完成后，项目边界内的植物种类的质量和数量也会得到很大的提高。在南召县有2万多种植物，包括国家重点保护植物中国红杉。",
  projectNanZhaoTitle13:"对当地居民的影响",
  projectNanZhaoTitleDetail3:"1989年，南召县被国务院确定为国家级重点贫困县。2011年，它再次被确定为全国扶贫开发重点县。改项目区内农村居民的收入低于当地的平均水平。",
  projectNanZhaoTitleDetail31:"优美的环境，丰富的森林资源，66%的森林覆盖率，促进了当地旅游业的发展，为当地人带来了可观的收入。南召县的木兰每年会吸引数以万计的游客参观，不仅给贫困人口带来了经济效益，也实现了生态效益和社会效益。诸如这样的生态旅游景点，为贫困人口创造了9000多个就业岗位，年平均收入在1万元左右。",
  projectNanZhaoTitleDetail32:"除了旅游业，当地人还利用环境和生物多样性改善带来的好处来发展其他产业，比如中草药种植。当地的中药产业创造了200多个就业岗位，其中有大约80名贫困人口。",
  projectNanZhaoTitle14: "林业管理技术培训",
  projectNanZhaoTitleDetail4:"在项目开发之初，教当地居民如何获得优质树苗以及必要的抚育和森林管理技术。他们还学会了如何生产高质量的树苗和成功种植树木的技能，以及如何防止树木遭受火灾和病虫害的方法。",
  projectNanZhaoRightDetail1: "VCS标准",
  projectNanZhaoRightDetail2: "平均每年减少515,373 tCO2e吨碳排放",
  projectNanZhaoRightDetail3: "27,380 公顷森林得到保护",
  projectNanZhaoRightDetail4: "项目期60年（2014-2073）",
  projectNanZhaoRightDetail5: "项目实现的可持续发展目标：",
  // 红山
  projectInnerHongShanbanner1:"洪山镇森林生态保护项目",
  projectInnerHongShanbanner2:"",
  projectInnerHongShanTitle1:"环境保护——火山遗迹",
  projectInnerHongShanTitleDetail1:"洪山镇的大洪山火山岩遗迹为中国四大历史火山遗址之一，植被覆盖率高达80%，岩层中含有罕见的火山遗迹。根据地质记录，该地区在寒武纪晚期(约5亿年前)发生了大规模的火山喷发，直到今天，18亿至5亿年前的熔岩岩层仍然完好无损。项目区拥有独特的地质景观，包括世界级的地质遗迹和丰富的煌斑岩（煌斑岩是钻石的源岩，目前该地的煌斑岩主要用于地质研究）。",
  projectInnerHongShanTitle2:"经济效益",
  projectInnerHongShanTitleDetail2:"森林保护活动促进了当地经济发展，生态旅游也因此产生，众多游客慕名而来，欣赏景区优美的风景、体验天然的温泉和当地的茶文化。",
  projectInnerHongShanTitle3:"有机茶产业",
  projectInnerHongShanTitleDetail3:"茶是这个地区世代相传的特产。该地山川秀美，江水碧蓝，云峰茶场因此产生，每年种植有机茶1.3万亩，加工茶叶5000吨。茶叶种植已成为该地区最大的主导产业，集种植、加工、贸易、茶文化和茶旅游于一体。每年都有很多游客来这里体验茶文化。",
  projectInnerHongShanTitle4:"生物多样性",
  projectInnerHongShanTitleDetail4:"根据洪山镇林业局的记录，20世纪80年代，由于森林资源的过度开发，导致该地区的生物多样性显著下降。例如，该地区在历史上一直是金钱豹的栖息地，但是它们已经近40年没有出现了。",
  projectInnerHongShanTitleDetail41:"然而，随着最近森林管理的改善，该地区的生态系统正在恢复。虽然金钱豹还没有返回该地区，但湖北洪山镇碳汇项目区也是其他很多极度濒危和国家保护动物物种的家园，如中国的大鲵和金钱龟，以及珍稀树木榉树和金丝楠木。",
  projectInnerHongShanTitleDetail42:"该项目结束了非法采伐森林的行为，水土流失和生物多样性得到改善，促进了当地可持续发展。",
  projectInnerHongShanRightDetail1:"VCS标准",
  projectInnerHongShanRightDetail2:"每年减少大约228,707 吨碳排放",
  projectInnerHongShanRightDetail3:"23,769.42公顷的森林得到保护",
  projectInnerHongShanRightDetail4:"当地人得到保护森林、动物和火山遗迹的教育",
  projectInnerHongShanRightDetail5:"创造了1000多个就业机会",
  projectInnerHongShanRightDetail6:"项目期限30年(2015至2044年)",
  projectInnerHongShanRightDetail7:"项目实现的可持续发展目标：",
  projectInnerHongShanMap1:"洪山镇森林生态保护项目",
  projectInnerHongShanMap2:"",
  // 方城
  projectInnerFangChengbanner1:"河南省方城、唐河植树造林项目",
  projectInnerFangChengTitle1:"加强森林互联互通，加强生物多样性保护",
  projectInnerFangChengTitleDetail1:"历史上方城、唐河的森林曾是温带落叶混交林和亚热带混交林，本项目选择了多种本地树种，即杨树、木兰、刺槐、柏树、松树、马尾松、核桃数。两县共有57科180余种木本植物和50余科300余种草本植物。",
  projectInnerFangChengTitle2:"加强水土保持、促进可持续发展",
  projectInnerFangChengTitleDetail2:"在项目实施前，方城、唐河等地均未进行过自然造林，至少10年的时间，项目区域内无森林覆盖。随着项目开展，在项目区域内禁止农耕、放牧、伐木等活动。通过植树和森林管理取代这些活动，该项目将有助于当地的水源保护和减轻土壤侵蚀。",
  projectInnerFangChengTitleDetail21:"",
  projectInnerFangChengTitle3:"为当地社区创造收入和就业机会",
  projectInnerFangChengTitleDetail3:"当地居民负责植树育苗，协助当地林业局完成造林工作。项目将增加本地居民的就业机会，并通过森林修复改善当地环境。",
  projectInnerFangChengTitle4:"森林保护",
  projectInnerFangChengTitleDetail4:"受雇于林业局的当地居民还将接受森林管理方面的职业培训，例如生产高质量树苗的方法、植树技术以及防火、虫害和疾病预防策略。这项培训将向居民提供生态和林业方面的新技能，促进向更可持续的地方经济转型。",
  projectInnerFangChengRightDetail1:"VCS&CCB",
  projectInnerFangChengRightDetail2:"每年减少碳排放405,092吨",
  projectInnerFangChengRightDetail3:"26,911 公顷森林得到保护",
  projectInnerFangChengRightDetail4:"项目期60年（2015-2075）",
  projectInnerFangChengRightDetail5:"项目实现的可持续发展目标：",
  projectInnerFangChengMap1:"河南省方城、唐河",
  projectInnerFangChengMap2:"植树造林项目",
  // 使命
  missionTitle1: "使命",
  missionAction1: "千予汇致力于开发有益于生态和当地居民的基于自然碳项目，提供经认证的高质量碳汇，助力世界各地的企业气候目标。",
  missionTitle2: "愿景",
  missionAction2: "成为全球南部地区最值得信赖的基于自然的碳减排来源。",

  servieceNewTitle: "我们能做什么",
  servieceNewPic1: "碳减排项目开发",
  servieceNewPic2: "环境资产交易",
  servieceNewPic3: "企业气候承诺",

  projectClassItemTitle1: "草原可持续管理",
  projectClassItemTitle2: "造林与再造林",
  projectClassItemTitle3: "森林经营管理",
  projectClassItemProjectTitle1: "甘肃瓜州草原可持续管理项目",
  projectClassItemProjectTime1: "在开发中",
  projectClassItemProjectTitle2: "甘肃肃北草原可持续管理项目",
  projectClassItemProjectTime2: "在开发中",
}


