import { 
  Form,
  FormItem,
  Input,
  Button,
  Carousel,
  CarouselItem,
 } from 'element-ui';

//封装组件
const element = {
	install: function (Vue) {
		Vue.use(Form)
		Vue.use(FormItem)
		Vue.use(Input)
		Vue.use(Button)
		Vue.use(Carousel)
		Vue.use(CarouselItem)
	}
}
export default element