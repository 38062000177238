<template>
  <div class="header ">
     <div class="header-logo">
       <img src="../../assets/images/header-mobile/logo.png" alt="logo">
     </div>
     <div class="nav" @click="changeNav">
       <img :src="navDefault" alt="nav">
       <ul class="nav-list" :class="locale === 'zh-CN'? '': 'nav-list-en'" v-show="showNav">
        <li>
          <router-link :to="{path:'/index'}">{{$t("home")}}</router-link>
        </li>
        <li>
          <a :href="`${$t('ahref')}`">{{$t("news")}}</a>
        </li>
        <li>
          <router-link :to="{path:'/provideService'}">{{$t("whatWeDo")}}</router-link>
        </li>
        <li>
          <router-link :to="{path:'/project'}">{{$t("projects")}}</router-link>
        </li>
        <li><router-link :to="{path:'/aboutUs'}">{{$t("aboutUs")}}</router-link></li>
        <li><router-link :to="{path:'/green-certificate'}">{{$t("whatWeDoSub4")}}</router-link></li>
        <li><router-link :to="{path:'/grassland-project'}">{{$t("whatWeDoSub5")}}</router-link></li>
        <li @click="scollButton">{{$t("contactUs")}}</li>
        <li>
          <span @click="changeLangageEn">En</span>/
          <span @click="changeLangageZh">中文</span>
        </li>
       </ul>
     </div>
  </div>
</template>
<script>
export default {
  name: "public-header",
  props:{
    // logoFlag: Boolean,
    // default: "true"
    logoFlag: {
      type:Boolean,
      default: true
    }
  },
  data(){
    return {
      pageHeight:0,
      nav: require("@/assets/images/header-mobile/nav.png"),
      navClose: require("@/assets/images/header-mobile/close.png"),
      navDefault: "",
      showNav:false,
      locale: ''
    }
  },
  created(){
    this.navDefault = this.nav;
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
  mounted(){
    
    this.getDomHeight();
  },
  methods: {
    changeNav() {  // 切换导航
      this.showNav = !this.showNav;
      if( this.showNav ) {
        this.navDefault = this.navClose;
      }else {
        this.navDefault = this.nav;
      }
    },
    scollButton() {
      let timer = null;
      let count = 0;
      timer = setInterval(() => {
        if(count >= 10){
          clearInterval(timer)
        }
        count++;
        window.scrollTo(0,(this.pageHeight/10)*count);
      },20)
    },
    getDomHeight() {
      this.pageHeight = document.getElementById("app").clientHeight;
    },
    // 切换语言
    changeLangageEn(){
      localStorage.setItem('locale',"en-US");
      location.reload();
    },
    changeLangageZh(){
      localStorage.setItem('locale',"zh-CN");
      location.reload();
    }
  },
  
}
</script>
<style scoped>

.header {
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
 .header-logo {
   width: 3.2rem;
   height: 0.8rem;
 }
.header-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.nav {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  width: 0.4rem;
  height: 0.28rem;
}
.nav img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.nav .nav-list {
  width: 3.76rem;
  /* height: 8.8rem; */
  position: absolute;
  right: -0.4rem;
  top: 0.68rem;
  background-color: #F3F7F8;
  z-index: 9;
}
.nav .nav-list-en {
  width: 7.5rem;
}
.nav .nav-list li:first-child {
  margin-top: 0.1rem;
}
.nav .nav-list li {
  box-sizing: border-box;
  width: 100%;
  height: 1rem;
  padding-left: 0.4rem;
  border-bottom: 1px solid #CBCBCB;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666;
  line-height: 1rem;
}
.nav .nav-list li a {
  display: block;
   width: 100%;
   color: #666;
}

</style>