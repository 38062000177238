export default {
  ahref: "http://news.qyh-investment.com",
  back:'Back to Staff',
  back1:'Back',
  whoWeAre: "Who We Are",
  whoWeAreContent1: "Beijing Qianyuhui Environmental Technology Co., Ltd. (QYH) was established in 2014 and has been active in the international carbon market since 2016. QYH's core business services include project investment, carbon offset verification consultation, and carbon offset sales. At the same time, we also provide professional consultation services for our clients to help them achieve their carbon neutral goals.",
  whoWeAreContent2: "QYH has invested in a number of forestry carbon projects in China. We continue to develop more projects at home which aim to help improve the environment and achieve sustainable development of local communities. We at QYH are anticipating continued growth in the voluntary offset market and hope that through market-based mechanisms and nature-based solutions, more people will join us to mitigate the effects of climate change. ",
  timeline: "Timeline",
  timeline1: "· Beijing Qianyuhui Environmental Technology Co., Ltd.",
  timelineyear1: "2014",
  timeline2: "· Successfully co-developed 3 VCS  forestry projects in Jiangxi and Inner Mongolia.",
  timelineyear2: "2016",
  timeline3: "· Established a carbon credit sales network of over 20 companies based in North America, Europe and East Asia.",
  timelineyear3: "2017-2019",
  timeline4: "· Independently-developed the Henan Fangcheng and Tanghe Afforestation Project. ",
  timeline41: "· Became members of the International Emissions Trading Association (IETA).",
  timeline42:"Independently-developed the Henan Fangcheng and Tanghe Afforestation Project.Became members of the International Emissions Trading Association (IETA).",
  timelineyear4: "2020",
  timeline5: "· Initiated the development of multiple ",
  timeline51: "forestry carbon projects across China.",
  timeline52:"· Established partnerships with top universities in China to achieve carbon neutrality. Recognized as a leading developer in China's VCM",
  timelineyear5: "2021",
  timeline6: "· Completed Pre-A Funding Round with Vickers Venture Partners",
  timeline61: "· Commenced development of the Guazhou Sustainable Grassland Management Project",
  timeline62:"· Commenced development of the Subei Sustainable Grassland Management Project Recognized as a leading developer in China's VCM",
  timelineyear6: "2022",
  team: "Team",
  teamItem1: "Carl Hao",
  teamItemPost1: "Chairman",
  teamItem2: "Neo Yu-yang Lin",
  teamItemPost2: "CEO",
  teamItemPost21:"Co-founder & CEO",
  teamItem3: "Nicholas Manthey",
  teamItem31: "",
  teamItem32: "",
  teamItemPost3: "COO",
  teamItemPost3Mobile: "Head of Development",
  teamItem4: "Beibei Song",
  teamItemPost4: "Head of Business Development",
  teamItem5: "Ryan Chen",
  teamItemPost5: "Project Director",
  teamItem6: "Cici Hao",
  teamItemPost6: "Chairman Assistant",
  teamItem7: "Junyuan Piao",
  teamItemPost7: "User Interface Designer",
  teamItemGet1:`Director of China Energy Conservation Association`,
  teamItemDetail1:`Through years of experience in business and investment, Carl has learned that preserving nature and local communities are our lasting wealth. To achieve these goals, he founded Qianyuhui Investment in 2014, hoping to reduce global greenhouse gas emissions through carbon trading. Carl is an investor in China's forestry carbon projects, an advocate for climate action, and an active participant in domestic and international climate conferences.`,
  teamItemGet2:`Master of Business Administration, Tamkang University 
  (Concentration on Emission Trading Systems)`,
  teamItemDetail2:`Neo has 10 years of experience in international business and is an expert in global carbon markets. He is committed to promoting the establishment of carbon trading systems and the development of emission reduction projects. As a carbon market expert, Neo has been invited to speak at events hosted by the World Bank, Asian Development Bank and Renmin University of China. In addition, he has actively participated at COP24, COP25 and other climate tech events. Neo hopes to reduce emissions through international carbon trading, improve the efficiency of global climate governance, as well as resolve the technological gap between developing and developed countries.`,
  teamItemGet3:`Master of International Studies: Energy, Resources, and 
  Environment, Johns Hopkins SAIS-Nanjing University`,
  teamItemDetail3:`Nicholas is responsible for developing growth opportunities at QYH Investment and building strategic partnerships, both domestic and international. He has focused his prior academic research on blockchain applications designed for carbon trading, especially within the forestry market segment. His publications include “Blockchain and Climate: A New Energy Frontier” (China-US Focus, 2018) and “The Impact of Blockchain Technology on the Voluntary Forest Carbon Offset Market" (Hopkins-Nanjing MA Thesis, 2019). Nicholas is professionally proficient in Mandarin Chinese and has been based in mainland China since 2010. `,
  teamItemGet4:`B.Sc. in Business Administration, Zhejiang International Studies University`,
  teamItemGet41:`The Hong Kong University of Science and Technology MSc. Environmental Science and Mangement`,
  teamItemDetail4:`Beibei Song is in charge of communication, negotiation and cooperation with global partners to support QYH Investment’s VCS forestry carbon projects in China and manages QYH Investment’s project portfolio and project development. Beibei Song has six years’ experience in the emissions trading industry and has a strong passion for protecting forests and reducing carbon emissions for their partners, as well as supporting local communities. Prior to joining QYH Investment, Beibei Song served in the special forces of the Chinese military for two years. `,
  teamItemGet5:`Bachelor's Degree in Business Administration
  University of Bedfordshire (UK)`,
  teamItemDetail5:"Ryan is mainly responsible for the communication with local government and communities,  branding of Qianyuhui in China and the maintenance of its partnership with our clients. Before joining Qianyuhui Environmental Protection, Ryan worked as the BD in China and UK. He has rich experience in business cooperation.",






// 新增移动端
aboutButton1: "About Us",
aboutButton2: "Timeline of QYH History",
course2016Title: "First investments in VCS IFM projects in China.",

course2018Title1: "Successfully issued credits from 2 VCS IFM projects.",
course2018Title2: "First participation at COP24 in Katowice, Poland with the honor to speak at the China Corner.",


course2019Title1: "Attended and spoke at COP25 in Madrid, Spain with members of the Climate Chain Coalition.",
course2019Title2: "Established a carbon credits sales network of over 20 companies based in North America, Europe and Southeast Asia.",
course2019Title3: "QYH’s CEO (Neo Lin) was invited as a speaker for Innovate4Climate.",


course2020Title1: "Registered first VCS afforestation project in Henan, China.",
course2020Title2: "Officially joined the International Emissions Trading Association (IETA).",

course2021Title1: "Completed pre-Series A round of funding with Vickers Venture Partners.",
course2021Title2: "Offset scope 3 emissions for Great Leap Brewing.",
course2021Title3: "Attended COP26 (Glasgow, Scotland) online.",


course2022Title1: "Successfully issued credits for QYH’s first VCS afforestation project.",
course2022Title2: "Strategic partnership established with CECEP (Hong Kong) Investment Co., Ltd.",
course2022Title3: "Joined the global Energy Attribute Certificate (EAC) market, providing solutions for clients' scope 2 emissions.",
course2022Title4: "Over 2 million tonnes of NBS carbon credits sold.",
course2022Title5: "Offset scope 1, 2 and 3 emissions for 9am, the pioneer and leader in workplace digitalization.",
course2022Title6: "",

course2023Title1: "Began project development for VCS Sustainable Grassland Management projects in Gansu, China.",
course2023Title2: "QYH’s CEO (Neo Lin) was invited as a speaker at Carbon Forward in Singapore.",
course2023Title3: "Officially signed a strategic cooperation agreement with China Venture Capital Co., Ltd.",
course2023Title4: "Jointly established the Carbon Sink Innovation Center in collaboration with the China Geological Survey (Kunming), Yunnan University, China Natural Resources Remote Sensing Center and the Ministry of Natural Resources.",
course2024Title1: "Expanding and managing nature-based carbon projects, creating tech innovation for the voluntary carbon market, and remaining committed to your climate pledge!",

} 