export default {
  bannerTitle:"Committed to Your Climate Pledge",
  moreTitle:"Learn More",
  //  服务开始
  serviceTitle:"What we do",
  serviceContentTitle:"Carbon Credit Development , Carbon Credit Sales Sustainable Development & Poverty Alleviation…",
  serviceItem1:"VCS & CCB certified forestry carbon credits",
  serviceItem2:"Sourced from China with diverse ecosystems",
  serviceItem3:"Afforestation & Improved Forest Management (IFM)",
  //  服务结束
  projectTitleTitle:"QYH Project Development and Offset Project Details",
// h5
  projectItemTitle1Mobile:"Jiangxi Province Le’an ",
  projectItemTitle2Mobile:"Inner Mongolia Wu’erqihan IFM",
  projectItemTitle3Mobile:"Inner Mongolia Keyihe IFM",
  projectItemTitle4Mobile:"Henan Fangcheng and Tanghe",

  //  项目开始
  projectTitle:"Projects",
  projectTitle1:"Projects",
  projectItemTitle1:"Jiangxi Province Le’an County Forest Farm Carbon Sink Project",
  projectItemyear1:"2006-2035",
  projectItemTitle2:"Inner Mongolia Wu’erqihan IFM  Project (Conversion of Logged to-Protected Forest)",
  projectItemyear2:"2013-2042",
  projectItemTitle3:"  Inner Mongolia Keyihe IFM Project  (Conversion of Logged to  Protected Forest)",
  projectItemyear3:"2013-2042",
  projectItemTitle4:"Henan Nanzhao Afforestation Project",
  projectItemyear4:"2014-2073",
  projectItemTitle5:"Hubei Hongshan IFM Project (Conversion of Logged to Protected Forest) ",
  projectItemyear5:"2015-2044",
  projectItemTitle6:"Henan Fangcheng and Tanghe Afforestation Project",
  projectItemyear6:"2015-2075",
  projectItemTitle7:"Inner Mongolia Wu’erqihan IFM  Project",
  projectItemyear7:"2013-2042",
  projectItemTitle8:"Inner Mongolia Keyihe IFM Project",
  projectItemyear8:"2013-2042",
  projectItemTitle9:"Hubei Hongshan IFM Project",
  projectItemyear9:"2015-2044",
  projectItemTitle10:"Inner Mongolia Wu’erqihan IFM  Project",
  projectItemTitle101:"",
  projectItemTitle11:"Inner Mongolia Keyihe IFM Project",
  projectItemTitle111:"",
  projectItemTitle12:"Henan Fangcheng and Tanghe Afforestation Project",
  projectItemTitle121:"",
  //  项目结束
  // 消息开始
  newsTitle:"News",
  newsContentTitle:"Follow and find the latest news from QYH! ",
  newsDetailed:"As the forests of Fangcheng and Tanghe have historically been temperate deciduous and subtropical mixed forests, a variety of native tree species have been selected……",
 // 消结束
//  会员单位
  memberOrganizations:"Member Organizations",
  // lianjie
  clientsPartners:"Strategic Partners",
    // 项目详情 江西
    projectJiangXiDetail1:"It is located in Le’an County, Jiangxi Province, in a subtropical humid monsoon climate zone. Prior to the project’s implementation, the land was open to logging for the sale of timber. Since 2006, as part of this Improved Forest Management (IFM) project, the land has been divided in to five zones for ecological rehabilitation according to tree species and age.Thus, the overall carbon storage capacity of the forest increases over time,allowing for the issuance of carbon credits.",
    projectJiangXiDetail2:"The efforts of the project contribute to the conservation of soil and local biodiversity, resulting in sustainable development of the forest and an estimated reduction of 2,600,406 tons of greenhouse gases over 30 years.",
    projectJiangXiTitle1: "Poverty Alleviation",
    projectJiangXiTitleDetail1: "Le’an county is classified as a key county for poverty alleviation and  development work. Most of the income from the sale of this project will be allocated to the locals in support of their communities. ",
    projectJiangXiTitle2: "Forest Protection",
    projectJiangXiTitleDetail2: "Before the implementation of the project, the trees were logged based on  a rotation according to the official timber harvesting plan. After the project  began, the logging of trees was avoided, causing an increase in the forest's carbon stocks.",
    projectJiangXiTitle3: "Good Health and Well-being",
    projectJiangXiTitleDetail3: "All tree species used are native to the region and a mixed species arrangement is used. The use of chemical pesticides is limited. Rather disease and pests are controlled by the mixed arrangement of tree species and other biological methods.",
    projectJiangXiTitle4: "Environmental Protection",
    projectJiangXiTitleDetail4: "Slash and burn site preparation and ploughing for soil preparation is not used.",
    projectJiangXiRightDetail1:"Verified Carbon Standard (VCS) Certified ",
    projectJiangXiRightDetail2:"866,802 tCO2e reduced annually",
    projectJiangXiRightDetail3:"7,746.7 hectares of forest protected",
    projectJiangXiRightDetail4:"8 towns in Le’an county",
    projectJiangXiRightDetail5:"30-year project lifespan (2006 to 2035)",
    projectJiangXiRightDetail6:"Sustainable Development Goals:",
    // 内蒙乌尔旗汉
    projectInnerWuerqihanbanner1:"Inner Mongolia Wu’erqihan IFM Project ",
    projectInnerWuerqihanbanner2:"(Conversion of Logged to Protected Forest)",
    projectInnerWuerqihanTitle1:"Poverty Alleviation",
    projectInnerWuerqihanTitleDetail1:"Income is generated for local rural residents through regular forest management. Since the implementation of the project, the local population’s standard of living has improved. The Wu’erqihan Forestry Bureau has set up a carbon credit management office, employing five people, and carbon credit monitoring stations at nine forestry farm locations, employing 45 people.  According to the official statistics, every 1 yuan (CNY) of increased output from the Wu’erqihan forestry project is predicted to drive 2-10 yuan (CNY)  of sustained social benefit.",
    projectInnerWuerqihanTitle12:"Protect Old-Growth Forests",
    projectInnerWuerqihanTitleDetail2:"Before the implementation of the project activity, the trees were logged  based on a valid and verifiable government-approved timber management  plan for harvesting the project area. The implementation of the project  activity converts the trees to protected forest.",
    projectInnerWuerqihanTitle13:"Economic-Environmental Transformation",
    projectInnerWuerqihanTitleDetail3:"Due to its unique natural environment and geographical location, Wu’erqihan attracts a large number of foreign tourists each year. Especially in recent  years, due to the increased ecological protections implemented in this  area, deforestation from the timber industry has gradually stopped, being  replaced by the development of tourism and green ecological products.  At present, hospitality, dining, handicrafts and forest products have become key local industries.",
    projectInnerWuerqihanRightDetail1: "Verified Carbon Standard (VCS) Certified ",
    projectInnerWuerqihanRightDetail2: "376,087 tCO2e reduced annually",
    projectInnerWuerqihanRightDetail3: "43,167 hectares of forest protected",
    projectInnerWuerqihanRightDetail4: "45 jobs created",
    projectInnerWuerqihanRightDetail5: "30-year project lifespan (2013 to 2042)",
    projectInnerWuerqihanRightDetail6: "Sustainable Development Goals：",
    projectInnerWuerqihanMap1:"Inner Mongolia Wu’erqihan IFM Project ",
    projectInnerWuerqihanMap2:"(Conversion of Logged to Protected Forest)",
      // 内蒙克一河林场
    projectInnerKeyihebanner1:"Inner Mongolia Keyihe IFM Project",
    projectInnerKeyihebanner2:"(Conversion of Logged to Protected Forest)",
    projectInnerKeyiheTitle1:"Protecting the Forest and Wildlife through Education",
    projectInnerKeyiheTitleDetail1:"Each year, the Keyihe Forestry Bureau implements special actions to rectify deforestation and reclaim forest land. The Forestry Bureau publicizes the importance of forest protection and is active in enforcing wildlife protection  laws. In addition, the bureau promotes Wetland Preservation Day and Bird  Week activities, as well as organizes and implements forest fire prevention  activities. These efforts of the Forestry Bureau amplify the significance of  wildlife and environmental protection and spread awareness among local  communities.",
    projectInnerKeyiheTitle12:"Diverse and Plentify Flora and Fauna",
    projectInnerKeyiheTitleDetail2:"The project area was once covered by dense primordial forest, where the quantity and biodiversity of wild animals and plants were plentiful. Prior to project implementation, the quantity and biodiversity of wild animals and plants had been reduced due to years of timber harvest. The implementation of the project has increased the stability of the ecosystem and enhanced biodiversity conservation by increasing forest cover and natural habitat connectivity.",
    projectInnerKeyiheTitle13:"Environment Protection and Economic-Environmental Transformation",
    projectInnerKeyiheTitleDetail3:"The project area was once covered by dense primordial forest, where the quantity and biodiversity of wild animals and plants were plentiful. Prior to project implementation, the quantity and biodiversity of wild animals and plants had been reduced due to years of timber harvest. The implementation of the project has increased the stability of the ecosystem and enhanced biodiversity conservation by increasing forest cover and natural habitat connectivity.",
    projectInnerKeyiheRightDetail1: "Verified Carbon Standard (VCS) Certified",
    projectInnerKeyiheRightDetail2: "100,279 tCO2e reduced annually",
    projectInnerKeyiheRightDetail3: "20,526 hectares of forest protected",
    projectInnerKeyiheRightDetail4: "200 jobs created",
    projectInnerKeyiheRightDetail5: "30-year project lifespan (2013 to 2042)",
    projectInnerKeyiheRightDetail6: "Sustainable Development Goals：",
    projectInnerKeyiheMap1:"Inner Mongolia Keyihe IFM Project ",
    projectInnerKeyiheMap2:"(Conversion of Logged to Protected Forest)",
    // 南召
    projectNanZhaobanner1:"Henan Nanzhao Afforestation Project",
    projectNanZhaoTitle1:"Impact on Climate",
    projectNanZhaoTitleDetail1:`The implementation of the project activity conserves the trees, making 
    them a protected forest that will reduce GHG emissions by about 31 million 
    tCO2e over 60 years. A monitoring system has also been put in place to 
    verify the removals of GHGs done by natural carbon sinks, which has 
    verified 418,299 tCO2e during the first monitoring period. This number 
    is expected to increase as the forest grows in size, increasing its carbon 
    storage capacity.`,
    projectNanZhaoTitle12:"Impact on Flora and Fauna",
    projectNanZhaoTitleDetail2:`
    This project is located at the southern foot of Funiu Mountain National 
Nature Reserve, which has more than 50 species of wildlife under key 
state protection, such as leopard (Panthera pardus) and Chinese Giant 
Salamander (Andrias davidianus). While these species are currently not 
found within the boundaries of the project, after the tree planting activity 
is completed, the ecological environment will be highly improved, and this 
will expand the habitat for protected animals.`,
    projectNanZhaoTitleDetail21:`After the planting activity is completed, the quality and quantity of the plant 
    species within the project boundary will also be highly improved. In Nazhao 
    County, there are more than 20,000 species of plants, including the Chinese 
    Yew (a national key-protected species).`,
    projectNanZhaoTitle13:"Impact on Locals",
    projectNanZhaoTitleDetail3:`In 1989, Nanzhao was identified as a state-level poverty-stricken county 
    by the State Council. And in 2011, it was again identified as a key county 
    for national poverty alleviation and development of decent work and 
    economic growth. The income of rural residents in the project area is lower 
    than the general level of local townships and counties.`,
    projectNanZhaoTitleDetail31:`The beautiful environment, rich forest resources, as well as a high forest 
    coverage rate of 66% all promote the development of local tourism and 
    bring considerable income for locals. Magnolias in Nanzhao attract tens of 
    thousands of visitors every year, which not only brings economic benefits to 
    the poor, but also realizes ecological and social benefits. Eco-tourist 
    attractions such as this have created over 9,000 jobs for poverty stricken 
    people with an annual per capita income of 10,000 CNY.`,
    projectNanZhaoTitleDetail32:`Other than tourism, the locals also use benefits from the environment and 
    the improved biodiversity to develop other industries, such as planting 
    medicinal herbs. The local Chinese medical industry has created more 
    than 200 jobs which includes about 80 poverty-stricken individuals.`,
    projectNanZhaoTitle14:"Solutions for Forestry Management",
    projectNanZhaoTitleDetail4:`At the beginning of the project development, local residents were taught 
    how to access good quality saplings or seedlings as well as the necessary 
    tending and forest management techniques. They also learned the skills 
    of producing high-quality seedlings and planting trees successfully, and 
    how to prevent planted trees from being subject to fires, pests and 
    diseases.`,
    projectNanZhaoRightDetail1: "Verified Carbon Standard (VCS) Certified ",
    projectNanZhaoRightDetail2: "515,373 tCO2e reduced annually",
    projectNanZhaoRightDetail3: "27,380 hectares of forest planted",
    projectNanZhaoRightDetail4: "60-year project lifespan (2014 to 2073)",
    projectNanZhaoRightDetail5: "Sustainable Development Goals：",
      // 红山
  projectInnerHongShanbanner1:"Hubei Hongshan IFM Project ",
  projectInnerHongShanbanner2:"(Conversion of Logged to Protected Forest) ",
  projectInnerHongShanTitle1:"Environmental Protection—Volcanic Remains",
  projectInnerHongShanTitleDetail1:`The project is located in one of the four major historical volcanic sites 
  in China, where vegetation coverage is up to 80% and rock formations 
  contain rare volcanic remains. According to geological records
  large-scale volcanic eruptions occurred in this region in the late Cambrian 
  period (about 500 million years ago) and to this day, lava rock formations 
  from 1.8 to 0.5 billion years ago remain intact. The project area is home to 
  a unique geological landscape that contains world-class geological relics 
  and an abundance of lamproite, a source rock for diamonds, which is used 
  for geological research.`,
  projectInnerHongShanTitle2:"Economic-Environmental Transformation",
  projectInnerHongShanTitleDetail2:`Thanks to the forest conservation activities, the local economy has greatly
  benefitted. Eco-tourism has developed considerably, as many tourists visit 
  the project area for its beautiful scenery, natural hot-springs, and local 
  teas. `,
  projectInnerHongShanTitle3:"Organic Tea Industry",
  projectInnerHongShanTitleDetail3:`With its beautiful mountains and blue rivers, tea has been a special 
  product in this area for generations. One such enterprise is the Yunfeng 
  Tea Farm, which cultivates 13,000 mu (1ha = 15mu) of organic tea and 
  processes 5,000 tonnes of tea annually. Tea cultivation has become the 
  largest leading industry in the region, integrating planting, processing, 
  trading, tea culture and tea tourism. Every year, many tourists come here 
  to experience tea culture.`,
  projectInnerHongShanTitle4:"Forest Protection and Enhancing Biodiversity",
  projectInnerHongShanTitleDetail4:`  According to the records of the Hongshan Forestry Bureau, the 
  over-exploitation of forest resources in the 1980's led to a significant 
  decrease in the region's biodiversity. For example, this part of Hubei 
  province has historically been home to leopard populations, though they 
  have not been sighted in nearly four decades.`,
  projectInnerHongShanTitleDetail41:`  However, with the recent efforts of improved forest management, the 
  area's ecosystem is recovering. While leopards have yet to return to the 
  region, the Hubei Hongshan IFM Project is home to critically endangered 
  and state-protected animal species such as the Chinese Giant Salamander 
  and Golden Coin Turtle, and rare trees like the Chinese zelkova and Phoebe 
  zhennan. `,
  projectInnerHongShanTitleDetail42:`  By implementing the project, the preservation of these species has laid the 
  foundation for the development of ecological research, including biology 
  education and study tours. As the project has ended the illegal harvest of 
  the forest, benefits to the environment have been realized, including the 
  control of soil erosion, maintaining ecological balance, and preserving 
  biodiversity.`,
  projectInnerHongShanRightDetail1:"Verified Carbon Standard (VCS) Certified ",
  projectInnerHongShanRightDetail2:"228,707 tCO2e reduced annually",
  projectInnerHongShanRightDetail3:"23,769.42 hectares of forest protected",
  projectInnerHongShanRightDetail4:`Locals educated to protect forest, 
  animals and volcanic remains`,
  projectInnerHongShanRightDetail5:"Over 1,000 jobs created",
  projectInnerHongShanRightDetail6:"30-year project lifespan (2015 to 2044)",
  projectInnerHongShanRightDetail7:"Sustainable Development Goals：",
  projectInnerHongShanMap1:"Hubei Hongshan IFM Project ",
  projectInnerHongShanMap2:" (Conversion of Logged to Protected Forest)",
  // 方城
  projectInnerFangChengbanner1:"Henan Fangcheng and Tanghe Afforestation Project ",
  projectInnerFangChengTitle1:`Enhancing Biodiversity Conservation by Increasing the 
  Connectivity of Forests`,
  projectInnerFangChengTitleDetail1:`As the forests of Fangcheng and Tanghe have historically been temperate 
  deciduous and subtropical mixed forests, a variety of native tree species 
  have been selected for the project, namely poplar, magnolia, false acacia
  cypress, torch pine, Chinese red pine, walnut and peach. In total, there are 
  more than 180 species of woody plants in 57 families and more than 300 
  species of herbaceous plants in more than 50 families that are native to 
  these two counties. `,
  projectInnerFangChengTitle2:`Improve Soil and Water Conservation and Contribute to 
  Sustainable Development`,
  projectInnerFangChengTitleDetail2:`
  Prior to the implementation of the project, there has been no natural
reforestation on the sites in either Fangcheng or Tangehe county, and all 
project sites have been barren (without forest cover) for at least 10 years.`,
  projectInnerFangChengTitleDetail21:`
  With the implementation of the project, activities such as farming, grazing, 
or timber harvesting are prohibited in the project area. By replacing these 
activities with tree planting and forest management, the project will 
contribute to local water conservation and mitigate soil erosion.`,
  projectInnerFangChengTitle3:"Generate Income and Job Opportunities for Local Communities",
  projectInnerFangChengTitleDetail3:`Local residents are responsible for planting tree saplings and assisting the 
  local Forestry Bureau in completing reports regarding afforestation 
  operations. The project will enhance job opportunities for local residents, 
  as well as improve the local environment through landscape restoration.`,
  projectInnerFangChengTitle4:"Forest Protection",
  projectInnerFangChengTitleDetail4:`Local residents employed by the Forestry Bureau will also receive 
  vocational training in forest management, such as methods for producing 
  high quality saplings, successful tree planting techniques, as well as fire, 
  pest and disease prevention strategies. This training will provide residents 
  with new skill sets in ecology and forestry, facilitating a transition to a more
   sustainable local economy.`,
  projectInnerFangChengRightDetail1:`Verified Carbon Standard (VCS) & 
  Climate, Community and Biodiversity (CCB) 
  Certified`,
  projectInnerFangChengRightDetail2:"405,092 tCO2e reduced annually",
  projectInnerFangChengRightDetail3:"26,911 hectares of forest planted",
  projectInnerFangChengRightDetail4:"60-year project lifespan (2015 to 2075)",
  projectInnerFangChengRightDetail5:"Sustainable Development Goals：",
  projectInnerFangChengMap1:"Henan Fangcheng and Tanghe",
  projectInnerFangChengMap2:" Afforestation Project",

  missionTitle1: "Mission",
  missionAction1: "At QYH, our mission is to develop high-quality carbon offset projects that benefit local communities and ecosystems, providing certified nature-based carbon removals for corporates around the world to better achieve their climate ambitions.",
  missionTitle2: "Vision",
  missionAction2: "Be the world's trusted source for nature-based removals across the Global South.",

  servieceNewTitle: "What we do",
  servieceNewPic1: "Carbon Project Development",
  servieceNewPic2: "Climate Assets Trading",
  servieceNewPic3: "Corporate Climate Commitments",

  projectClassItemTitle1: "Sustainable Grassland Management",
  projectClassItemTitle2: "Afforestation and Reforestation",
  projectClassItemTitle3: "Improved Forest Management",
  projectClassItemProjectTitle1: "Gansu Guazhou Sustainable Grassland Management Project",
  projectClassItemProjectTime1: "Under Development",
  projectClassItemProjectTitle2: "Subei Sustainable Grassland Management Project",
  projectClassItemProjectTime2: "Under Development",
}







 

 




