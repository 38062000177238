export default {
  address1:"北京市 海淀区 中关村大街59号",
  address2:"中国人民大学文化大厦403B",
  address3:"",
  pleaseContactUs:"通过下方的方式联系我们：",
  phone:"+86-130-1185-5440",
  website:"info@qyh-investment.com",
  companyName:"北京千予汇环保科技有限公司"
}







 