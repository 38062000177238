export default {
  carbonCreditDevelopment: "碳汇项目开发",
  carbonCreditDevelopment1: "VCS&CCB林业碳汇",
  carbonCreditDevelopment2: "保护中国多样化的生态系统",
  carbonCreditDevelopment3: "植树造林&森林经营管理",

  carbonCreditSales: "碳汇销售",
  carbonCreditSales1: "我们的客户遍布欧洲、北美和东亚",
  carbonCreditSales2: "与中国资深的碳汇开发企业合作",

  sustainableDevelopmentPovertyAlleviation: "促进可持续发展&帮助消除贫困",
  sustainableDevelopmentPovertyAlleviation1: "千予汇碳汇项目将会支持当地社区的发展，减少该地区的贫困人口",
  sustainableDevelopmentPovertyAlleviation2: "为当地创造更多的就业岗位，这其中也包括森林管理岗位",

  carbonNeutralityEducation: "碳中和推广",
  carbonNeutralityEducation0: "",
  carbonNeutralityEducation1: "参加碳汇市场的国际活动",
  carbonNeutralityEducation2: "举办中国碳汇交易、碳中和以及宣传环保理念的研讨会",

  carbonNeutralityConsulting: "碳中和咨询",
  carbonNeutralityConsulting0: "",
  carbonNeutralityConsulting1: "为我们的客户提供碳排放抵消服务",
  carbonNeutralityConsulting2: "与国际领先的ESG公司合作共同为我们的客户提供咨询服务",


  provideProjectItemTitle1: "碳汇项目开发",
  provideProjectItemTitle2: "环境资产交易",
  provideProjectItemTitle3: "企业气候承诺",
  provideProjectItemContent1: "千予汇以VCS，CCB，CCER等标准为依托进行自有碳减排项目开发，并提供项目开发的咨询服务。目前在中国开发了多个高质量的林草类项目。",
  provideProjectItemContent2: "千予汇为国际买家提供最符合需求的中国碳汇及绿证资源，推广优质的国内项目。",
  provideProjectItemContent3: "企业如何担当社会责任，应对气候变化问题，已经成为一个重要的话题。千予汇可以协助您实现气候承诺。",
}