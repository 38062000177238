export default {
  certificateTitle: "EAC(Energy Attribute Certificates)",
  certificateContentTitle1: "What is an EAC?",
  certificateContentExplain11: "EAC (Energy Attribute Certificates) refers to a kind of certificate that tracks the type, location, production time and other information about renewable electricity generation. Each EAC accounts for 1MWh of renewable electricity.",
  certificateContentExplain12: "Common types of EACs includes European's GO (Guarantees of Origin), North America's REC, and I-REC (International Renewable Energy Certificates).",
  certificateContentExplain13: "Consumers may purchase Bundled EACs (along with renewable electricity) or Unbundled EACs. After the certificate is redeemed, consumers may make a unique claim for the environmental attributes of electricity. Companies often use EACs to reduce their Scope 2 emissions.",
  
  certificateContentTitle2: "What does an EAC do?",
  certificateContentExplain21: "EACs are an effective market instrument to counter environmental risks. They can contribute to the construction of more renewable electricity facilities and lead to a regional power source transition from fossil fuels to renewables.",
  certificateContentExplain22: "EACs give consumers a cost-effective way to procure renewable electricity to meet their climate targets and to reduce carbon emissions.",

  certificateContentTitle3: "EAC in China",
  certificateContentExplain3: "There is mainly two types of EACs operating in China: I-REC and GEC (Green Electricity Certificate).",

  certificateContentExplain3Sub1: "I-REC",
  certificateContentExplain3Sub2: "I-REC (International Renewable Energy Certificate) is the most widely used EAC standard. I-RECs operate in more than sixty countries around the world, covering a wide range of renewable energy sources, including hydropower, wind power and solar power generation.",
  certificateContentExplain3Origin: "Source：https://www.trackingstandard.org/world-map/ ",
  certificateContentExplain3Sub4: "The regulatory body of I-REC is a non-profit organisation: International Tracking Standard Foundation (formerly known as I-REC Standard Foundation). Globally, the standard has high credibility and is recognized by frameworks and initiatives such as Carbon Disclosure (CDP) and RE100. Currently, State Grid Corporation of China is the issuer for China's I-REC.",
  certificateContentExplain3Sub5Title: "GEC",
  certificateContentExplain3Sub51: "Launched in 2017, China's GEC system can be used for renewable electricity consumption accounting and certification. As there are only voluntary procurement policies in place, with mandatory policies pending, the vast majority of GECs are used by companies and individuals for the purpose of fulfilling their social responsibility or voluntarily supporting national climate targets.",
  certificateContentExplain3Sub52: "At present, the types of GECs that can be issued include wind power, solar power, hydropower, biomass, etc., including all archived renewable energy power generation projects in China.",

  certificateContentTitle4: "How to Use EAC",
  certificateContentExplain4: "To use EACs, simply follow the below steps: Calculate, Plan, Purchase, Redeem, Claim",
  certificateContentExplainSub1: "1.Calculate electricity usage.",
  certificateContentExplainSub2: "2.Plan a renewable electricity usage target and the method to achieve it.",
  certificateContentExplainSub3: "3.Purchase and Redeem EACs, obtaining redemption statements from the issuer (EACs cannot be transferred to a third party after redemption/cancellation). ",
  certificateContentExplainSub4: "4.Claim credible renewable energy use and disclosure (following standards such as SBTi, RE100, CDP, etc.).",
  certificateContent4Origin: "I-REC Redemption Certificate, QYH customer case",

  certificateContent4SubTitle1: "Why do businesses use EACs?",
  certificateContent4SubContent1: "1.For businesses , since most renewable electricity doesn't directly generate carbon emissions, using EACs can reduce their carbon footprint associated with electricity consumption (Scope 2 emissions). This helps participants in both mandatory and voluntary markets achieve their climate targets.",
  certificateContent4SubContent2: "2.EACs can be used to increase the proportion of renewable electricity consumption. ",
  certificateContent4SubContent3: "In China, GECs can be used to account for the mandatory renewable electricity consumption responsibilities.",
  certificateContent4SubContent4: "Additionally, EACs can be used for voluntary commitments to green electricity usage, such as RE100, an initiative joined by many global companies like Google and Apple. These companies will not only require themselves to achieve 100% renewable electricity usage by 2025, but also require that their subsidiaries and suppliers fulfill this commitment.",
  certificateContent4SubContent5: "3.By using EACs, businesses can build eco-friendly models and demonstrate their leadership in addressing climate change. More so than ever before, consumers and investors are paying increasing attention to ESG ratings, especially about how a company plans to address climate risks.",
  certificateContent4SubContent6: "4.Although businesses can achieve the above targets by directly procuring renewable electricity, EACs are more cost-effective. Moreover, China's renewable electricity trading market has a relatively short development history, making it challenging to meet direct procurement targets.",


  certificateContent4SubTitle2: "Can businesses use I-RECs and GECs to make credible claims?",
  certificateContent4SubContent7: 'The short answer is "yes".',
  certificateContent4SubContent8: 'According to I-TRACK, I‑REC(E) can be used for a variety of (voluntary) requirements including Scope 2 reporting, national energy reporting, and general End-user claims, and allows all electricity users to make a conscious and evidence-based choice for electricity. According to the statement in the "Q&A for Journalists" released by the National Development and Reform Commission and the National Energy Administration, GEC (Green Energy Certificates) not only embody the environmental value of renewable energy electricity, but also serve as an accurate tool for calculating the consumption of renewable energy electricity, and is also an internationally recognized proof of green electricity consumption.',
  certificateContent4SubTitle3: "How to choose between GEC and I-REC?",
  certificateContent4SubContent9: "Depending on the needs of the buyer, GECs or I-RECs might both be suitable.",
  certificateContent4SubContent10: "The GEC standard is developed and managed in China, acting as the only EAC that can be used to fulfill domestic renewable energy consumption responsibilities.",
  certificateContent4SubContent11: "However, renewable energy facilities in China can still issue I-RECs, which have higher international recognition than GECs and can be used globally to account for Scope 2 emissions.",
  certificateContent4SubContent12: "Enterprises can evaluate the advantages and disadvantages of both according to their usage needs before making a choice. The main differences between the two are summarized as follows.",

  certificateContent4SubContentMobile8: "According to I-TRACK, I‑REC(E) can be used for a variety of (voluntary) requirements including Scope 2 reporting, national energy reporting, and general End-user claims, and allows all electricity users to make a conscious and evidence-based choice for electricity.",
  certificateContent4SubContentMobile81:'According to the statement in the "Q&A for Journalists" released by the National Development and Reform Commission and the National Energy Administration, GEC (Green Energy Certificates) not only embody the environmental value of renewable energy electricity, but also serve as an accurate tool for calculating the consumption of renewable energy electricity, and is also an internationally recognized proof of green electricity consumption.',
  seeImage: 'Image',
  certificateTitleMobile: "EAC",
  certificateTitleMobile1: "(Energy Attribute Certificates)",
}

















