export default {
  address1:"Room 403B,Renmin University of China Culture Square,",
  address2:"59 Zhongguancun Ave., Haidian Dist., Beijing, China",
  address3:"",
  pleaseContactUs:"For inquiries, please contact us at:",
  phone:"+86-130-1185-5440",
  website:"info@qyh-investment.com",
  companyName:"Beijing Qianyuhui Environmental Technology Co., Ltd."
}

