<template>
  <div id="app" :class="i18n.locale=='zh-CN'?'zh-spacing':'word-spacing'">
    <router-view />
    <!-- <public-footer></public-footer> -->
  </div>
</template>
<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#app.zh-spacing .spacing6 {
  letter-spacing: 6px;
}
#app.word-spacing .spacing6 {
  letter-spacing: 0px;
  word-spacing: 3px;
}
#app.zh-spacing .spacing5 {
  letter-spacing: 5px;
}
#app.word-spacing .spacing5 {
  letter-spacing: 2px;
  word-spacing: 2px;
}
#app.word-spacing .news-content-right span{
  width: 428px;
  display: inline-block;
}
@font-face {
  font-family: Italic;
  src: url(./assets/font/Italic.ttf) format(truetype);
}
</style>
