export default {
  carbonCreditDevelopment: "Carbon Credit Development",
  carbonCreditDevelopment1: "VCS & CCB certified forestry carbon credits",
  carbonCreditDevelopment2: "Sourced from 4 Chinese provinces with diverse ecosystems",
  carbonCreditDevelopment3: "Afforestation & Improved Forest Management (IFM)",
  carbonCreditSales: "Carbon Credit Sales",
  carbonCreditSales1: "Sales network of international buyers in Europe, North America, & East Asia",
  carbonCreditSales2: "Cooperate with leading carbon asset developers in China",
  sustainableDevelopmentPovertyAlleviation: "Sustainable Development & Poverty Alleviation",
  sustainableDevelopmentPovertyAlleviation1: "Proceeds from sales support local communities & impoverished rural households",
  sustainableDevelopmentPovertyAlleviation2: "Create jobs in sustainable development, including forest management techniques ",
  carbonNeutralityEducation: "Carbon Neutrality",
  carbonNeutralityEducation0: "Education",
  carbonNeutralityEducation1: "Participate in international events within the carbon trading industry",
  carbonNeutralityEducation2: "Conduct workshops on carbon trading, carbon neutrality, & environmental awareness in China ",
  carbonNeutralityConsulting: "Carbon Neutrality",
  carbonNeutralityConsulting0: "Consulting",
  carbonNeutralityConsulting1: "Offset emissions for our partners",
  carbonNeutralityConsulting2: "Cooperate with leading ESG service providers",

  provideProjectItemTitle1: "Carbon Project Development",
  provideProjectItemTitle2: "Climate Assets Trading",
  provideProjectItemTitle3: "Corporate Climate Commitments",
  provideProjectItemContent1: "QYH is developing carbon projects within the frameworks of VCS, CCB and CCER, having developed a number of high quality NBS projects located in China. QYH also provides consultancy on project development.",
  provideProjectItemContent2: "QYH provides global buyers with suitable carbon credits and EACs, and introduces high-quality domestic projects. ",
  provideProjectItemContent3: "How to address climate change as a business is a complex process. QYH is here to assist you in fulfilling your climate pledge.",
}