export default {
  carbonTitle: "碳减排项目开发",

  carbonSubTitle1: "千予汇如何确保项目的质量",
  carbonQualityTitle1: "无双重计算",
  carbonQualityContent1: "不同碳汇标准所在的注册系统是独立运作的，这可能导致同一碳汇项目的重复注册或重复计算。千予汇确保使用国际碳汇标准（如VCS或Gold Standard）注册的项目不会同时注册CCER（中国核证自愿减排量），并确保千予汇的项目不会被重复用于气候目标。",
  carbonQualityTitle2: "明确土地所有权",
  carbonQualityContent2: "中国土地所有权结构复杂，并且具有地域性的差异。在碳汇项目开始之前，千予汇会对项目所在地的土地所有权和土地使用权进行确认，确保每个项目在法律上充分考虑到所有利益相关者。同时，项目区域内的所有利益相关者都会被告知碳汇项目开发的相关信息。",
  carbonQualityTitle3: "项目边界清晰",
  carbonQualityContent3: "一些基于自然的碳汇项目（如草地和造林）涉及的土地面积非常大，通常位于偏远或农村地区。为每个项目划定明确的边界有助于提高减排计算的准确性，提高项目的公信力和影响力。例如，草地项目必须排除包括道路和房屋在内的区域，因为它们与碳减排项目活动无关。",
  carbonQualityTitle4: "保护当地居民的权利",
  carbonQualityContent4: "为确保项目实施不对当地居民的基本生活产生负面影响，千予汇采取了多项措施。这包括定期监测、与利益相关者的交流，以及与当地社区和政府的对话，以深入了解当地的情况。",
  carbonQualityTitle5: "项目文件的完整性",
  carbonQualityContent5: "量化、监测、报告和核证是确保碳汇项目质量的四个重要步骤。同时，数据收集、实地访问和第三方评估贯穿于项目开发的整个周期，以保持透明度和准确性。",
  carbonQualityTitle6: "额外性",
  carbonQualityContent6: "额外性的评估充满挑战，但是千予汇严格遵循方法学的要求，并采用遥感、实地访问、参考本地记录、访谈当地人以及参考专业文献等手段，以确定每个项目的额外性。与此同时，这些方法也提高了基线排放情景的真实性。基线用于计算每个项目的气候影响，而真实准确的基线有助于避免高估减排量和过度发放碳汇量。",
  carbonQualityContent61: "",

  carbonSubTitle2: "主要开发标准",
  carbonStandardContent1: "国际核证碳标准是气候组织、国际排放交易协会及世界经济论坛联合于 2005年创立。VCS项目由非盈利的独立协会管理，要求项目自愿碳减排必须是真实、可测算、永久、独立核实和唯一。VCS是目前较为成熟的碳补偿标准，也是世界上应用最广的碳减排计量标准。VCS的项目类型与 CDM项目类型基本相同，但 VCS获得了减排行业的广泛支持。",
  carbonStandardContent2: "生物多样性认证 是由气候社区和生物多样性联盟于2003年提出的，宗旨是提高碳减排项目的质量与多重效益，增加碳汇减排项目对于生态环境、人类社区的可持续性发展，近几年广为国际企业认可。",
  carbonStandardContent3: "CCER是国家核证自愿减排量的缩写。它是指对我国境内特定项目的温室气体减排效果进行量化核证，并在国家温室气体自愿减排交易注册登记系统中登记的温室气体减排量，于 2012年 3月启动，暂停于 2017年 3月。2023年 10月 20日，生态环境部正式公布《温室气体自愿减排交易管理办法（试行）》，标志着 CCER正式重启。",


  carbonSubTitle3: "主要开发类型",

  carbonContent1: "千予汇专注于开发“基于自然的解决方案 ”（简称 NBS）的项目。NBS项目涵盖", 
  carbonContent2: "植树造林、林业管理和草地恢复", 
  carbonContent3: "等活动，将二氧化碳隔离到生物圈中（如树木和土壤），从而去除大气中的二氧化碳。", 
  carbonBoxContent: "额外价值：通过将二氧化碳储存在自然系统中的方式，NBS项目不仅有助于生态恢复和保护生物多样性，还提高了自然系统的经济价值：项目中所产生的碳汇在为当地居民提供经济支持的同时，也促进了当地自然资源的可持续管理。",
  carbonProjectItemTitle1: "造林项目",
  carbonProjectItemContent1: "造林项目指的是在 “不毛之地 ”（至少 10年没有森林覆盖）的地区进行植树活动。对于这种项目，需要建立基线情景，并通过森林生长中的额外碳固存量来计算每年可以发行的碳汇。",
  carbonProjectItemTitle2: "草原项目",
  carbonProjectItemContent2: "可持续性草原管理碳汇项目（简称：草原碳汇项目），是指通过修复治理退化草原等一系列措施后，扭转土地持续退化造成土壤碳储量减少的情景，以牧草作为媒介提高草地土壤中的碳储量，实现碳减排和增汇；",
  carbonProjectItemContent21: "点击了解草原项目详细介绍。",
  carbonProjectItemTitle3: "森林经营管理项目",
  carbonProjectItemContent3: "森林经营管理项目简称为IFM，依托现有的森林进行开发。在 IFM项目中，天然林能够生长超过其基线情景，以此增加森林固碳能力。",

}
