export default {
  certificateTitle: "绿证（能源属性证书）",
  certificateContentTitle1: "EAC基本介绍",
  certificateContentExplain11: "EAC（Energy Attribute Certificates，能源属性证明）是一种电子证书，用于记录绿色电力的来源、种类、产生时间等各种属性。常见的EAC有可再生能源绿色电力证书 (GEC)，国际可再生能源注册证书I-REC，欧洲的Guarantees of Origin（GO）等。",
  certificateContentExplain12: "每张证书对应一兆瓦时的可再生能源电力，发电机构通常可以向消费者打包出售绿色电力和EAC，也可以把两者分开出售。企业一般购买绿证核销范围2的排放。消费者购买EAC之后可以独家声明相应可再生电力带来的环境效益，减少碳足迹。",
  certificateContentExplain13: "",

  certificateContentTitle2: "EAC的作用",
  certificateContentExplain21: "面对气候变化，EAC是一个有效的应对工具。通过市场机制，它能够直接助力更多可再生能源发电项目的建设，从而促使一个地区从传统电力转向绿色电力。",
  certificateContentExplain22: "EAC给企业提供了成本高效的采购绿色电力的途径，得以达到其设定的绿色电力使用目标，减少碳排放。",

  certificateContentTitle3: "中国EAC",
  certificateContentExplain3: "中国大陆境内可发行的EACs主要有两种：I-RECs和GEC。",

  certificateContentExplain3Sub1: "I-REC",
  certificateContentExplain3Sub2: "I-REC (E) 全称为国际绿证证书/国际可再生能源证书，是目前使用最广泛，国际认可度很高的EAC标准。在全球有六十多个国家中可以发行I-REC，涵盖多种可再生能源，包括水电、风电、光伏发电等。",
  certificateContentExplain3Origin: "来源：https://www.trackingstandard.org/world-map/ ",
  certificateContentExplain3Sub4: "I-REC的监管机构为非盈利组织国际跟踪标准基金会 International Tracking Standard Foundation（前身为国际可再生能源基金会 I-REC Standard Foundation）。该标准在全球有很高的可信度，受到碳披露（CDP）、RE100等机构及倡议的认可。目前，中国I-REC由国家电网承担第三方核证工作。",
  certificateContentExplain3Sub5Title: "GEC",
  certificateContentExplain3Sub51: "中国的绿证制度于2017年开始试行，可用于可再生能源电力消费量核算、可再生能源电力消费认证等。由于目前实行自愿认购制度，强制约束交易相关制度仍不完善，因此成交的绿证绝大部分都是企业及个人出于履行社会责任或支持国家政策的目的而认购。",
  certificateContentExplain3Sub52: "目前可发行的GEC种类有风电、太阳能发电、水电、生物质能等，包括所有已建档的可再生能源发电项目。",

  certificateContentTitle4: "EAC的使用流程",
  certificateContentExplain4: "使用EAC的步骤可以简单分为：计算、计划、采购、注销、声明",
  certificateContentExplainSub1: "1.计算电力使用情况。",
  certificateContentExplainSub2: "2.计划想要达成怎样的绿色电力使用目标，通过何种途径进行。",
  certificateContentExplainSub3: "3.购买EAC，并进行注销（注销之后无法再转让给第三方）获得核销证书。",
  certificateContentExplainSub4: "4.发布具有公信力的披露及声明（SBTi，RE100，CDP等）。",
  certificateContent4Origin: "I-REC 核销证明样式 千予汇以往客户案例",

  certificateContent4SubTitle1: "企业为什么要使用EAC？",
  certificateContent4SubContent1: "1.对于企业来说，由于大部分绿色电力不直接产生碳排放，使用EAC可以减少消耗电力产生的碳排放（即范围2排放）。有助于强制碳市场和自愿碳市场参与者完成其减碳目标。",
  certificateContent4SubContent2: "2.EAC可以被用来提高可再生能源电力消费比例。",
  certificateContent4SubContent3: "在中国，GEC可用于核算绿电消纳责任。",
  certificateContent4SubContent4: "此外，EAC也可被用于自愿做出的绿电使用承诺，许多世界知名公司如谷歌、苹果都加入了RE100倡议，他们不仅需要自己在2025年前达到100%使用绿色电力的目标，而且还会要求其子公司、供应商等都履行这一承诺。",
  certificateContent4SubContent5: "3.此外，使用EAC还能够打造低碳绿色的形象，展示企业在气候变化问题上的领导力。如今，ESG评分成为消费者和投资者参考的重要因素，企业能否考虑到并有效应对气候风险受到越来越多的关注。",
  certificateContent4SubContent6: "4.尽管企业可以通过直接采购绿电的方式达到以上目标，但其成本较高，且中国绿电交易市场发展历史较短，可能难以达到采购目标。",

  certificateContent4SubTitle2: "企业能否使用I-RECs和GEC进行可信的绿电使用声明？",
  certificateContent4SubContent7: "可以。",
  certificateContent4SubContent8: "I-REC官网显示，I-REC(E)可用于各种声明，包括范围 2 报告、各国能源系统报告、自愿声明等，并允许所有电力用户自主选择核证的用电。根据国家发改委，国家能源局发布的《答记者问》一文的说法，GEC既是可再生能源电力环境属性价值体现，又是精准的可再生能源电力消费量核算工具，也是国际通行的绿色电力消费证明方式。",
  certificateContent4SubTitle3: "I-REC和GEC有什么区别，该如何选择？",
  certificateContent4SubContent9: "GEC在中国国内是最权威的绿色证书，且是绿色电力购买对应环境属性的唯一证明，可用于完成国内的可再生能源消纳责任。但国际上对I-REC的认可度更高。",
  certificateContent4SubContent10: "企业可根据自己的使用需要，评估两者的优劣，再选择使用。两者的主要差异归纳如下。",
  certificateContent4SubContent11: "",
  certificateContent4SubContent12: "",
  certificateContent4SubContentMobile8: "I-REC官网显示，I-REC(E)可用于各种声明，包括范围 2 报告、各国能源系统报告、自愿声明等，并允许所有电力用户自主选择核证的用电。",
  certificateContent4SubContentMobile81: "根据国家发改委，国家能源局发布的《答记者问》一文的说法，GEC既是可再生能源电力环境属性价值体现，又是精准的可再生能源电力消费量核算工具，也是国际通行的绿色电力消费证明方式。",

  seeImage: '查看原图',
  certificateTitleMobile: '绿证（能源属性证书）',
  certificateTitleMobile1: '',
}


