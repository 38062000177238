import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const constantRoutes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index/index-mobile")
  },
  { // 首页
    path: "/index",
    name: "index",
    component: () => import("@/views/index/index-mobile")
  },
  { //  项目
    path: "/project",
    name: "project",
    component: () => import("@/views/project/index-mobile")
  },
  { // 服务
    path: "/provideService",
    name: "provideService",
    component: () => import("@/views/provideService/index-mobile")
  },
  { // 关于我们
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("@/views/aboutUs/index-mobile"),
    // children: [
      
    // ]
  },
  // 人物详情开始
  { 
    path: "/CarlHao",
    name: "carl",
    component: () => import("@/views/aboutUs/Detail/carl-mobile"),
  },
  {
    path: "/BeibeiSong",
    name: "Beibei",
    component: () => import("@/views/aboutUs/Detail/beiBei-mobile"),
  },
  {
    path: "/RyanChen",
    name: "chenChen",
    component: () => import("@/views/aboutUs/Detail/chenChen-mobile"),
  },
  {
    path: "/NeoYuyangLin",
    name: "neo",
    component: () => import("@/views/aboutUs/Detail/neo-mobile"),
  },
  {
    path: "/NicholasManthey",
    name: "nicholasManthey",
    component: () => import("@/views/aboutUs/Detail/nicholasManthey-mobile"),
  },
  //  人物详情结束
  // 项目详情开始
  { 
    path: "/forestFarmCarbonSinkProject-Jiangxi",
    name: "forestFarmCarbonSinkProject-Jiangxi",
    component: () => import("@/views/project/Detail/forestFarmCarbonSinkProject-Jiangxi-mobile"),
  },
  {
    path: "/conversionOfLoggedToProtectedForest-InnerMongolia",
    name: "conversionOfLoggedToProtectedForest-InnerMongolia",
    component: () => import("@/views/project/Detail/conversionOfLoggedToProtectedForest-InnerMongolia-mobile"),
  },
  {
    path: "/conversionOfLoggedToProtectedForest-Keyihe",
    name: "conversionOfLoggedToProtectedForest-Keyihe",
    component: () => import("@/views/project/Detail/conversionOfLoggedToProtectedForest-Keyihe-mobile"),
  },
  {
    path: "/afforestationProject-Nanzhao",
    name: "afforestationProject-Nanzhao",
    component: () => import("@/views/project/Detail/afforestationProject-Nanzhao-mobile"),
  },
  {
    path: "/conversionOfLoggedToProtectedForest-Hongshan",
    name: "conversionOfLoggedToProtectedForest-Hongshan",
    component: () => import("@/views/project/Detail/conversionOfLoggedToProtectedForest-Hongshan-mobile"),
  },
  {
    path: "/afforestation-Fangcheng",
    name: "afforestation-Fangcheng",
    component: () => import("@/views/project/Detail/afforestation-Fangcheng-mobile"),
  },
  // 项目详情结束
  {
    path: "/corporate-climate-commitment",
    name: "corporate-climate-commitment",
    component: () => import("@/views/corporate/index-mobile.vue"),
  },
  {
    path: "/green-certificate",
    name: "green-certificate",
    component: () => import("@/views/certificate/index-mobile.vue"),
  },
  {
    path: "/grassland-project",
    name: "grassland-project",
    component: () => import("@/views/grassland/index-mobile.vue"),
  },
  {
    path: "/guazhou-project",
    name: "guazhou-project",
    component: () => import("@/views/guazhou/index-mobile.vue"),
  },
  {
    path: "/carbon",
    name: "carbon",
    component: () => import("@/views/carbon/index-mobile.vue"),
  },
  {
    path: "/asset-trad",
    name: "asset-trad",
    component: () => import("@/views/assetTrad/index-mobile.vue"),
  },
]
const createRouter = () => new VueRouter({
  mode: 'hash', 
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
const routerMobile = createRouter();
export default routerMobile;