export default {
  corporateTitle: "企业气候承诺",
  corporateSubTitle1: "作为企业，如何担当社会责任，应对气候变化问题，已经成为一个重要的话题。",
  corporateSubTitle2: "千予汇可以协助您实现气候承诺，我们提供的服务包括：",
  corporateItemTitle1: "测算碳足迹",
  corporateItemSubTitle1: "单一产品、流水线，企业本身的排放等。",
  corporateItemTitle2: "建立科学的减排目标、设计减排方案",
  corporateItemTitle3: "实施减排行动，采购绿色电力证书或投资碳减排项目",
  corporateItemSpan1: "绿证",
  corporateItemSpan2: "草原可持续管理碳汇项目",
  corporateItemTitle4: "发布可信、透明的披露及声明",
  corporateItemSubTitle4: "SBTi，RE100，CDP等。",
  corporateCase: "案例",
  corporateCOntent: "千予汇为9am Halo系列升降桌提供碳中和服务。通过对产品生命周期碳排放评估，9am可以了解整个生产过程——包含原材料获取（含原材料运输）和生产加工阶段——的温室气体排放量，以此作为依据来制定之后的气候行动策略，从而减少对环境的影响。9am选择了高质量的林业碳汇项目产生的碳汇量来抵消生产活动过程中的温室气体排放。除了购买碳汇，9am还会采取更多措施来减少生产过程中产生的温室气体。",
}