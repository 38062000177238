export default {
  corporateTitle: "Corporate Climate Commitments",
  corporateSubTitle1: "How to address climate change as a business is a complex process. QYH can assist you in ",
  corporateSubTitle2: "fulfilling your climate pledge. The services we provide include:",
  corporateItemTitle1: "Measure carbon footprints for individual products, production lines, or corporate emissions ",
  corporateItemSubTitle1: "",
  corporateItemTitle2: "Establish science-based emission reduction targets and design emission reduction strategies.",
  corporateItemTitle3: "Implementing emission reduction actions, including procuring green electricity certificates or investing in carbon projects.",
  corporateItemSpan1: "EAC",
  corporateItemSpan2: "Sustainable Grassland Management Projects",
  corporateItemTitle4: "Make credible and transparent disclosures and statements that demonstrate your commitment to sustainability with frameworks like SBTi, RE100, CDP",
  corporateItemSubTitle4: "",
  corporateCase: "Case",
  corporateCOntent: "QYH provides carbon offset services for 9am's Halo series height-adjustable desks. Through a LCA of the product from cradle to gate, 9am gains insights into the greenhouse gas emissions during raw material acquisition (including transportation) and production stages. This information serves as a foundation for formulating climate action strategies to reduce environmental impact. 9am has chosen to offset the greenhouse gas emissions from the production process by investing in carbon credits generated from high-quality forestry carbon projects. In addition to purchasing carbon credits, 9am plans to implement further measures to reduce greenhouse gas emissions generated during the production process.",
}

 



