export default {
  guazhouTitle: "甘肃瓜州草原可持续管理项目",

  guazhouExplainContent: "在项目实施之前，过度放牧造成优质牧草减少、自然再生能力下降，项目区域内的大部分草地处于中度退化状态。本项目通过草地恢复相关措施，生物多样性和土地质量有望在未来几年得到持续改善。这将为动物创造更好的生存化境和更多的栖息地，并促进土壤有机碳的积累。",

  guazhouExplainData1Title: "30万吨/年",
  guazhouExplainData1Content: "二氧化碳储存于土壤中",
  guazhouExplainData2Title: "14万",
  guazhouExplainData2Content: "公顷草地",
  guazhouExplainData3Title: "27种",
  guazhouExplainData3Content: "国家级保护动物",
  guazhouExplainData4Title: "450个",
  guazhouExplainData4Content: "工作岗位",

  guazhouExplainDetail1: "项目编号：VCS 4218",
  guazhouExplainDetail2: "项目名称：甘肃瓜州草原可持续管理项目",
  guazhouExplainDetail3: "地理位置：甘肃省瓜州县",
  guazhouExplainDetail4: "方法学：VM0026",
  guazhouExplainDetail5: "项目开始日期：2020年6月1日",


  measureTitle: "草原修复措施",
  measureProject1Title: "种草（60,768亩）",
  measureProject1Explain: "在此区域中，选择四种适宜的本地草种进行种植。种植完成后，建立围栏，在第一年间禁止放牧，让草地有足够的时间生长。",
  measureProject2Title: "轮牧（1,269,374亩）",
  measureProject2Explain: "依据特定的放牧顺序、周期和时间安排，轮流在不同区域放牧，以确保草地有更多时间生长。",
  measureProject3Title: "休牧（651.464亩）",
  measureProject3Explain: "在这些区域修建了围栏，每年草地的生长季节（五月至八月）禁止放牧。",
  measureProject4Title: "其他",
  measureProject4Explain: "招募草管员并进行培训。他们会定期巡视草地，监管潜在的鼠害和火灾风险。此外，还在草原上修建了若干鹰巢。这不仅为本地物种提供额外的栖息地，增加了生物多样性，还避免化学农药的使用，减少对环境的影响。",
  measureStautsOpen: "展开",
  measureStautsClose: "收起",



  grassSeedsTitle: "草种选择",
  grassSeedsExplain1: "草种的选择需要遵循项目区域的气候与土壤条件。瓜州县年均蒸发量远远超过年均降水量。",
  grassSeedsExplain11: "受气候变化和过度放牧的影响，土壤盐碱化较为严重。",
  grassSeedsExplain12: "项目区土质主要为壤土、沙壤土和沙土。",
  grassSeedsExplain2: "由于较能适应当地恶劣环境，四种本地物种被选择出来：分别是冰草、羊茅、碱草和马蔺。",
  grassSeedsExplain21: "即使在霜冻灾害的情况下，这些草在第二年仍能顺利生长。",
  grassSeedsExplain22: "此外，本地物种也更适合为本地动物提供食物和栖息地。",
  grassSeedsProject1Title: "马蔺",
  grassSeedsProject1Title1: "Iris lacteal",
  grassSeedsProject1Explain: "马蔺根系发达，固土能力极强，有利于水土保持。在干旱、贫瘠的土地条件下，马蔺的地上部分茎叶的生长量会减低20%以上，而根系生长量却会增加10%以上，入土深度可达1米以上。耐高温、干旱、水涝、盐碱，适应性极强。另外，马蔺具有很强的抗病虫害和鼠害能力，其他植物与其混植后极少发生病虫害。马蔺还有药用价值和其他经济价值。",
  grassSeedsProject2Title: "冰草",
  grassSeedsProject2Title1: "Agropyron cristat",
  grassSeedsProject2Explain: "沙生冰草根系发达，根系外有沙套保护，叶片窄小且内卷，干旱时气孔闭合。干旱严重时虽然生长停滞，但叶片仍能保持绿色，一遇到雨水即可迅速恢复生长。因此沙生冰草具有极强的抗旱、抗寒、和固沙能力，也是中国栽培植物中最耐干旱的禾本科牧草之一。该植物可以在-40℃的低温下安全越冬，耐高温，对土壤不苛求。",
  grassSeedsProject3Title: "羊茅",
  grassSeedsProject3Title1: "Festuca ovina",
  grassSeedsProject3Explain: "羊茅对环境变化具有较强的耐受能力。其菌根真菌增加了对水分和养分的吸收，能更好的适应贫瘠的土壤环境，这也是植物群落结构形成的潜在决定因素。这种草可以抵抗寒冷，干旱和轻度盐渍化土壤。其生长随生态环境变化，在干旱或盐渍较重的环境，生长低矮；而生长在水分条件较好，盐渍化程度较轻的地方（河冲积平原、荒地或水渠边沿），能生长成繁茂的株丛，并以强壮的根茎迅速繁衍。",
  grassSeedsProject3ExplainOrigin: "图片来源于中国植物图像库：https://ppbc.iplant.cn/tu/994834",
  grassSeedsProject4Title: "碱茅",
  grassSeedsProject4Title1: "Puccinellia distans",
  grassSeedsProject4Explain: "碱茅生于平原绿洲和山区的河谷草甸、盐化低地草甸、水溪边及田边地埂，在中度至重度盐渍化土壤上能良好生长。",


  influenceTitle: "项目影响",
  influenceSubTitle1: "生物多样性",
  influenceExplain: "瓜州县有27 种国家保护动物。其中国家一类保护动物 7 种，国家二类保护动物 20 种，《濒危野生动植物国际贸易公约》（CITES）保护动物22种，以及8种受国际自然保护联盟红色名录（IUCN）保护的动物，涵盖了濒危（EN）、易危（VU）、近危（NT）等级别的动物。通过提高草地的质量和可利用性，提供适宜的食物和栖息地条件，可为本地动物提供更好的生存和繁殖条件。",
  influenceSubTitle2: "重点保护物种",
  influenceProject1Title: "戈壁盘羊",
  influenceProject1Title1: "Ovis darwini",
  influenceProject1SubTitle1: "中国生物多样性红色名录：濒危",
  influenceProject1SubTitle2: "世界自然保护联盟红色名录：近危",
  influenceProject1Explain: "戈壁盘羊是一种生活在蒙古高原和中国西北部的野生羊类。雄性盘羊拥有两只巨大的角，最长甚至可达190厘米，重达23千克。猎人常常为了收集这对美丽的犄角而捕杀戈壁盘羊，再加上过度放牧、草原退化等因素，目前处于濒危状态。由于生态环境的修复为野生动物提供了良好的栖息环境，2023年再次在瓜州记录到盘羊活动的情况。",
  influenceProject2Title: "鹅喉羚",
  influenceProject2Title1: "Gazella subgutturosa",
  influenceProject2SubTitle1: "中国野生动物保护级别：II",
  influenceProject2SubTitle2: "世界自然保护联盟红色名录：易危",
  influenceProject2Explain: "鹅喉羚，本地人俗称“黄羊”。在甘肃瓜州南部和北部均可发现它们的踪迹。它们栖息于沙漠、砂砾平原和石灰岩高原。20世纪初，鹅喉羚曾经广泛地分布于中东亚，在几乎每个沙漠及半沙漠地区都能发现它的踪影。到了2001年，其整个种群仅有12万至14万之间。其受到的主要威胁包括无节制的猎取和栖息地破坏。栖息地破坏主要是经济和农业发展所致。",
  influenceProject3Title: "雪豹",
  influenceProject3Title1: "Panthera uncia",
  influenceProject3SubTitle1: "中国野生动物保护级别：I",
  influenceProject3SubTitle2: "世界自然保护联盟红色名录：易危",
  influenceProject3Explain: "在瓜州县南部3000米以上的高山上偶尔出现雪豹游猎活动的踪迹。雪豹栖息于中亚和南亚的山脉地带。全球成年个体数量估计不足1万只，且预计到2040年将下降约10%，被列为世界自然保护联盟红色名录的易危物种。雪豹的生存主要受到非法捕猎、栖息地破坏等威胁",

  residentTitle: "当地居民",
  residentExplain1: "如果没有此项目存在，草原将进一步恶化，严重威胁生态系统和牧民的生计。为了避免破坏草原生态的可持续性，应当采取合理的放牧管理，包括轮牧、休牧等措施。长期来看，这个项目将为当地居民带来许多好处，包括可持续的收入、更多的就业机会和更高的生态价值。",
  residentExplain2: "在项目实施期间，居民获得了短期工作机会，例如种草、围栏建设，以及更长期的草管员工作。",
  residentExplain21: "所有参与种草和围栏建设的员工每天获得180人民币的报酬。草管员每年获得约2000人民币的报酬。",
  residentExplain3: "大多数受雇的居民属于少数民族。大约有450名当地牧民参与了种草和围栏建设，其中包括150名妇女。其中有130名当地牧民（包括45名妇女）被聘为草管员。在这450个工作机会中，有130个是长期职位，320个是贯穿整个项目计入期内的临时工作。",
  residentExplain31: "",
  residentExplain32: "",
  downloadTitle: "文档下载",
  downloadProject1: "项目描述文档",
  downloadProject2: "项目认证报告",
  downloadProject3: "项目监测报告",
  downloadProject4: "项目核证报告",
}





