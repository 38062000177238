export default {
  assettradTitle: "环境资产交易",
  assettradSubTitle1: "实施减排措施是一项重要且长远的目标，",
  assettradSubTitle2: "通过千予汇提供的产品，能够切实地加快实现目标的过程。",
  
  assettradMapItem1: "多年的国内和国际碳市场经验",
  assettradMapItem2: "完成百万吨国际碳汇交易",
  assettradMapItem3: "客户遍布于欧洲、美国、和新加坡等地",
  assettradMapItem4: "多年的国内和国际碳市场经验",

  assettradProjectItem1Title: "碳减排项目",
  assettradProjectItem1SubTitle: "千予汇专注于自然类项目，可以提供造林、草原可持续管理、森林经营管理在内的多种碳汇资源。",
  assettradProjectItem2Title: "绿色电力证书",
  assettradProjectItem2SubTitle: "千予汇能够提供光伏、风电、水电三种绿证，助力企业实现可再生能源用电目标。",

}